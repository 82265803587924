import { StringInput } from '@uesco/crane-builder-core/models/input'
import { CRANE_SET_DIRECTION } from '@/store/types';
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {},
})
export default class Direction extends Vue {
    @Prop({ required: true }) craneImage!: string;

    get directions(): StringInput[] {
        return this.$store.state.directions;
    }

    get direction(): string {
        return this.$store.state.crane.direction;
    }

    set direction(d: string) {
        this.$store.commit(CRANE_SET_DIRECTION, d);
    }
}
