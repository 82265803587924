export const GET_CRANE_TYPES = 'builder/getCraneTypes' as const;
export const GET_CRANE_IMAGES = 'builder/getCraneImages' as const;
export const GET_CRANE_CAPACITES = 'builder/getCraneCapacites' as const;
export const GET_SHOW_LOADING = 'builder/showLoading' as const;
export const GET_NOTIFY_PDF_CREATED = 'builder/notifyPdfCreated' as const;
export const GET_SPEED = 'builder/getSpeed' as const;
export const GET_PDF_LINK = 'builder/getPdfLink' as const;
export const SET_CRANE_TYPE_CAPACITES = 'builder/setCraneTypeCapacites' as const;
export const SET_SELECTED_CRANE_TYPE = 'builder/setSelectedCraneType' as const;
export const SET_HOIST_AMOUNT = 'builder/hoistAmount' as const;
export const SET_QUOTE_REQUTESTED = 'builder/setQuoteRequested' as const;
export const SET_USER_SUBMITTED_FORM = 'builder/userSubmittedForm' as const;
export const SET_FORM_SUBMISSION_STATUS = 'builder/setFormSubmissionStatus' as const;
export const SET_ENDPOINT = 'builder/setEndpoint' as const;
export const SET_SHOW_LOADING = 'builder/setShowLoading' as const;
export const SET_NOTIFY_PDF_CREATED = 'builder/setNotifyPdfCreated' as const;
export const SET_PDF_LINK = 'builder/setPdfLink' as const;
export const RESET = 'builder/reset' as const;
export const SUBMIT_BUILD_REQUEST = 'builder/submitBuildRequest' as const;
export const CRANE_SET_CAPACITY = 'crane/setCapacity' as const;
export const CRANE_SET_SPAN_FEET = 'crane/setSpanFeet' as const;
export const CRANE_SET_SPAN_INCH = 'crane/setSpanInch' as const;
export const CRANE_SET_SPAN_FRACTIONS = 'crane/setSpanFractions' as const;
export const CRANE_SET_LIFT = 'crane/setLift' as const;
export const CRANE_SET_DIRECTION = 'crane/setDirection' as const;
export const CRANE_SET_ASCE_RAIL_FEET = 'crane/setAsceRailFeet' as const;
export const CRANE_SET_ASCE_RAIL_INCH = 'crane/setAsceRailInch' as const;
export const CRANE_SET_ASCE_RAIL_FRACTIONS = 'crane/setAsceRailFractions' as const;
export const CRANE_SET_ASCE_RAIL_SIZE = 'crane/setAsceRailSize' as const;
export const CRANE_SET_LOWEST_OBSTRUCTION_HEIGHT_FEET = 'crane/setLowestObstructionHeightFeet' as const;
export const CRANE_SET_LOWEST_OBSTRUCTION_HEIGHT_INCH = 'crane/setLowestObstructionHeightInch' as const;
export const CRANE_SET_LOWEST_OBSTRUCTION_HEIGHT_FRACTIONS = 'crane/setLowestObstructionHeightFractions' as const;
export const CRANE_SET_VOLTAGE = 'crane/setVoltage' as const;
export const CRANE_SET_CONTROL_TYPE = 'crane/setControlType' as const;
export const CRANE_SET_SPEED = 'crane/setSpeed' as const;
export const CRANE_SET_CONTROL_METHOD = 'crane/setControlMethod' as const;
export const CRANE_SET_SERVICE_REQUIREMENT = 'crane/setServiceRequirement' as const;
export const CRANE_SET_BRIDGE_LIMIT = 'crane/setBridgeLimit' as const;
export const CRANE_SET_TROLLEY_LIMIT = 'crane/setTrolleyLimit' as const;
export const CRANE_SET_COLLISION_AVOIDANCE = 'crane/setCollisionAvoidance' as const;
export const CRANE_SET_WARNING_DEVICE = 'crane/setWarningDevice' as const;
export const HOIST_SET_HOIST_MANUFACTURER = 'crane/setHoistManufacturer' as const;
export const HOIST_SET_HOIST_A_CAPACITY = 'hoist/setHoistACapacity' as const;
export const HOIST_SET_HOIST_B_CAPACITY = 'hoist/setHoistBCapacity' as const;
export const QUOTE_SET_NAME = 'quote/setName' as const;
export const QUOTE_SET_COMPANY = 'quote/setCompany' as const;
export const QUOTE_SET_CITY = 'quote/setCity' as const;
export const QUOTE_SET_STATE = 'quote/setState' as const;
export const QUOTE_SET_PHONE = 'quote/setPhone' as const;
export const QUOTE_SET_EMAIL = 'quote/setEmail' as const;
export const QUOTE_SET_BUILDING = 'quote/setBuilding' as const;
export const QUOTE_SET_RUNWAY_REQUIRED = 'quote/setRunwayRequired' as const;
export const QUOTE_SET_RUNWAY_SPEC = 'quote/setRunwaySpec' as const;
export const QUOTE_SET_INSTALLATION_REQUIRED = 'quote/setInstallationRequired' as const;
export const QUOTE_SET_PROJECT_TIMELINE = 'quote/setProjectTimeline' as const;
