import CRANE_TYPES from "./craneTypes";
import { stringNotNullAndHasLength } from "../utils/validation";
import { formatMeasurement } from "../utils/formatting";

export type CraneListing = {
    craneWeight: number;
    calcCraneCapacity: number;
    craneCapacity: string;
    craneType: string;
    craneBaseType: string;
    cranePrice: string;
    calcCraneSpan: string;
    craneSpanFeet: number;
    craneSpanInch: number;
    craneWheelLoad: string;
    craneHpRpm: string;
    craneBeam: string;
    craneFlange: string;
    craneWheelDiameter: string;
    craneWheelBase: string;
    craneElectrificationSideApproach: number;
    craneSideApproach: number;
    craneDistanceAboveRail: number;
    craneOverhang: string;
    craneEndApproachFront: string;
    craneEndApproachBack: string;
}

export type Measurement = {
    feet: number;
    inch: number;
    fractions: string;
}

export type AsceRail = {
    measurements: Measurement;
    size: string;
}

export class Crane {
    public asceRail: AsceRail = { measurements: { feet: 0, inch: 0, fractions: '' }, size: '' };
    public bridgeLimit = '';
    public capacity = 0;
    public collisionAvoidance = '';
    public controlMethod = '';
    public controlType = '';
    public craneType = CRANE_TYPES.NONE;
    public craneBaseType = '';
    public direction = '';
    public lift = 0;
    public lowestObstructionHeight: Measurement = { feet: 0, inch: 0, fractions: '' };
    public serviceRequirement = '';
    public span: Measurement = { feet: 0, inch: 0, fractions: '' };
    public speed = '';
    public trolleyLimit = '';
    public voltage = '';
    public warningDevice = '';

    get isCapacityValid(): boolean {
        return this.capacity > 0;
    }

    get isControlMethodValid(): boolean {
        return stringNotNullAndHasLength(this.controlMethod);
    }

    get isControlTypeValid(): boolean {
        return stringNotNullAndHasLength(this.controlType);
    }

    get isLiftValid(): boolean {
        return this.lift > 0;
    }

    get isServiceRequirementValid(): boolean {
        return stringNotNullAndHasLength(this.serviceRequirement);
    }

    get isSpanValid(): boolean {
        return this.span.feet > 0;
    }

    get isSpeedValid(): boolean {
        return stringNotNullAndHasLength(this.speed);
    }

    get isVoltageValid(): boolean {
        return stringNotNullAndHasLength(this.voltage);
    }

    get isValid(): boolean {
        return this.isCapacityValid && this.isControlMethodValid && this.isControlTypeValid && this.isServiceRequirementValid && this.isSpeedValid && this.isVoltageValid;
    }

    get spanFormatted(): string {
        return formatMeasurement(this.span);
    }

    get lowestObstuctedHeightFormatted(): string {
        return formatMeasurement(this.lowestObstructionHeight);
    }

    get topAsceRailFormatted(): string {
        return formatMeasurement(this.asceRail.measurements);
    }

    public static hydrateFromJson(crane: Crane): Crane {
        const c = new Crane();
        c.asceRail = crane.asceRail;
        c.bridgeLimit = crane.bridgeLimit;
        c.capacity = crane.capacity;
        c.collisionAvoidance = crane.collisionAvoidance;
        c.controlMethod = crane.controlMethod;
        c.controlType = crane.controlType;
        c.craneType = crane.craneType;
        c.direction = crane.direction;
        c.lift = crane.lift;
        c.craneBaseType = crane.craneBaseType;
        c.lowestObstructionHeight = crane.lowestObstructionHeight;
        c.serviceRequirement = crane.serviceRequirement;
        c.span = crane.span;
        c.speed = crane.speed;
        c.trolleyLimit = crane.trolleyLimit;
        c.voltage = crane.voltage;
        c.warningDevice = crane.warningDevice;
        return c;
    }
}
