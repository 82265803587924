









import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
    name: 'Spinner',
})
export default class Spinner extends Vue {
}
