import { Measurement } from "../models/crane";

type FractionDecimal = {
    fraction: string;
    decimal: number;
  }
const hasFractions = (f: string): boolean => (f !== null && f.length > 0 && f !== '0');

export const formatMeasurement = (measurement: Measurement): string => {
    const { feet, inch, fractions } = measurement;
    let format = '';

    if (feet) {
        format += `${feet}'`;
        if (inch && hasFractions(fractions)) {
            format += ` ${inch}-${fractions}"`;
        } else if (inch) {
            format += ` ${inch}"`;
        } else if (fractions) {
            format += ` ${fractions}"`
        } else {
            format += ' 0"';
        }
        return format;
    }

    if (inch && hasFractions(fractions)) {
        format += `${inch}-${fractions}"`;
    } else if (inch) {
        format += `${inch}"`;
    } else if (fractions) {
        format += `${fractions}"`
    } else {
        format = `0' 0"`
    }
    // Default
    return format;
};
