import { CraneImages } from '@/models/craneImages';
import CRANE_TYPES from '@uesco/crane-builder-core/models/craneTypes';
import { NumberInput, StringInput } from '@uesco/crane-builder-core/models/input';
import { GetterTree } from 'vuex'
import IRootState from './state'
import {
    GET_CRANE_CAPACITES,
    GET_CRANE_IMAGES,
    GET_CRANE_TYPES,
    GET_NOTIFY_PDF_CREATED,
    GET_PDF_LINK,
    GET_SHOW_LOADING,
    GET_SPEED,
} from './types'

const getters: GetterTree<IRootState, IRootState> = {
    [GET_CRANE_TYPES](): string[] {
        return [
            CRANE_TYPES.DOUBLE_GIRDER_TOP_RUNNING,
            CRANE_TYPES.SINGLE_GIRDER_TOP_RUNNING,
            CRANE_TYPES.SINGLE_GIRDER_UNDER_RUNNING,
        ];
    },
    [GET_CRANE_CAPACITES](state: IRootState): NumberInput[] {
        let capacites = new Set<number>();
        if (state.crane.craneType === CRANE_TYPES.DOUBLE_GIRDER_TOP_RUNNING) {
            capacites = state.craneTypeCapacites.get('dgtrdm') || new Set<number>();
        } else if (state.crane.craneType === CRANE_TYPES.SINGLE_GIRDER_TOP_RUNNING) {
            capacites = state.craneTypeCapacites.get('sgtrdm') || new Set<number>();
        } else if (state.crane.craneType === CRANE_TYPES.SINGLE_GIRDER_UNDER_RUNNING) {
            capacites = state.craneTypeCapacites.get('sgurdm') || new Set<number>();
        }
        const values: NumberInput[] = [{ value: 0, text: 'Select Ton' }];
         capacites.forEach((c: number) => {
             let val: NumberInput | null = null;
            if (c === 0.25) val = { value: 0.25, text: '1/4 TON' };
            if (val === null && c === 0.5) val = { value: 0.5, text: '1/2 TON' };
            if (val === null && c === 0.75) val = { value: 0.75, text: '3/4 TON' };
            if (val !== null) {
                values.push(val);
            } else {
                values.push({ value: c, text: `${c} TON` });
            }
        });
        return values;
    },
    [GET_CRANE_IMAGES](state: IRootState): CraneImages {
        switch (state.crane.craneType.toString()) {
            case CRANE_TYPES.DOUBLE_GIRDER_TOP_RUNNING.toString():
                return {
                   top: 'https://uesco-cdn.nyc3.cdn.digitaloceanspaces.com/builder/crane-dgtrdm-top.png',
                   middle: 'https://uesco-cdn.nyc3.cdn.digitaloceanspaces.com/builder/crane-dgtrdm-middle.png',
                   bottom: 'https://uesco-cdn.nyc3.cdn.digitaloceanspaces.com/builder/crane-dgtrdm-bottom.png',
                };
            case CRANE_TYPES.SINGLE_GIRDER_TOP_RUNNING:
                return {
                    top: 'https://uesco-cdn.nyc3.cdn.digitaloceanspaces.com/builder/crane-sgtrdm-top.jpg',
                    middle: 'https://uesco-cdn.nyc3.cdn.digitaloceanspaces.com/builder/crane-sgtrdm-middle.jpg',
                    bottom: 'https://uesco-cdn.nyc3.cdn.digitaloceanspaces.com/builder/crane-sgtrdm-bottom.jpg',
                 };
            case CRANE_TYPES.SINGLE_GIRDER_UNDER_RUNNING:
                return {
                    top: 'https://uesco-cdn.nyc3.cdn.digitaloceanspaces.com/builder/crane-sgurdm-top.png',
                    middle: 'https://uesco-cdn.nyc3.cdn.digitaloceanspaces.com/builder/crane-sgurdm-middle.png',
                    bottom: 'https://uesco-cdn.nyc3.cdn.digitaloceanspaces.com/builder/crane-sgurdm-bottom.png',
                 };
            default:
                return {
                    top: '',
                    middle: '',
                    bottom: '',
                };
        }
    },
    [GET_SHOW_LOADING](state: IRootState): boolean {
        return state.showLoading;
    },
    [GET_NOTIFY_PDF_CREATED](state: IRootState): boolean {
        return state.notifyPdfCreated;
    },
    [GET_PDF_LINK](state): string {
        return state.pdfLink;
    },
    [GET_SPEED](state): StringInput[] {
        const speeds = [
            { value: '50 FPM', text: '50 FPM' },
            { value: '80 FPM', text: '80 FPM' },
            { value: '100 FPM', text: '100 FPM' },
            { value: '120 FPM', text: '120 FPM' },
        ];
        if (state.selectedCraneType === CRANE_TYPES.DOUBLE_GIRDER_TOP_RUNNING) {
            speeds.push({ value: '150 FPM', text: '150 FPM' });
            speeds.push({ value: '200 FPM', text: '200 FPM' });
        }
        return speeds;
    },
};

export default getters;
