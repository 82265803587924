import { Component, Vue } from 'vue-property-decorator'
import { QUOTE_SET_BUILDING, QUOTE_SET_CITY, QUOTE_SET_COMPANY, QUOTE_SET_EMAIL, QUOTE_SET_INSTALLATION_REQUIRED, QUOTE_SET_NAME, QUOTE_SET_PHONE, QUOTE_SET_PROJECT_TIMELINE, QUOTE_SET_RUNWAY_REQUIRED, QUOTE_SET_RUNWAY_SPEC, QUOTE_SET_STATE, SET_QUOTE_REQUTESTED } from '@/store/types';
import { StringInput } from '@uesco/crane-builder-core/models/input';
import { Quote } from '@uesco/crane-builder-core/models/quote';

@Component({
    components: {}
})
export default class QuoteComponent extends Vue {
    get quote(): Quote {
        return this.$store.state.quote;
    }

    get userSubmittedForm(): boolean {
        return this.$store.state.userSubmittedForm;
    }

    get states(): StringInput[] {
        return this.$store.state.usStates;
    }

    get buildings(): StringInput[] {
        return this.$store.state.buildings;
    }

    get projectTimelines(): StringInput[] {
        return this.$store.state.projectTimelines;
    }

    get quoteRequested(): boolean {
        return this.$store.state.quoteRequested;
    }

    set quoteRequested(requested: boolean) {
        this.$store.commit(SET_QUOTE_REQUTESTED, requested);
    }

    get name(): string {
        return this.quote.name;
    }

    set name(n: string) {
        this.$store.commit(QUOTE_SET_NAME, n);
    }

    get isNameInvalid(): boolean {
        return this.userSubmittedForm && this.quoteRequested && this.quote.isNameValid === false;
    }

    get company(): string {
        return this.quote.company;
    }

    set company(c: string) {
        this.$store.commit(QUOTE_SET_COMPANY, c);
    }

    get isCompanyInvalid(): boolean {
        return this.userSubmittedForm && this.quoteRequested && this.quote.isCompanyValid === false;
    }

    get city(): string {
        return this.quote.city;
    }

    set city(c: string) {
        this.$store.commit(QUOTE_SET_CITY, c);
    }

    get isCityInvalid(): boolean {
        return this.userSubmittedForm && this.quoteRequested && this.quote.isCityValid === false;
    }

    get state(): string {
        return this.quote.state;
    }

    set state(s: string) {
        this.$store.commit(QUOTE_SET_STATE, s);
    }

    get isStateInvalid(): boolean {
        return this.userSubmittedForm && this.quoteRequested && this.quote.isStateValid === false;
    }

    get phone(): string {
        return this.quote.phone;
    }

    set phone(p: string) {
        this.$store.commit(QUOTE_SET_PHONE, p);
    }

    get isPhoneInvalid(): boolean {
        return this.userSubmittedForm && this.quoteRequested && this.quote.isPhoneValid === false;
    }

    get email(): string {
        return this.quote.email;
    }

    set email(e: string) {
        this.$store.commit(QUOTE_SET_EMAIL, e);
    }

    get isEmailInvalid(): boolean {
        return this.userSubmittedForm && this.quoteRequested && this.quote.isEmailValid === false;
    }

    get building(): string {
        return this.quote.building;
    }

    set building(b: string) {
        this.$store.commit(QUOTE_SET_BUILDING, b);
    }

    get isBuildingInvalid(): boolean {
        return this.userSubmittedForm && this.quoteRequested && this.quote.isBulidingValid === false;
    }

    get projectTimeline(): string {
        return this.quote.projectTimeline;
    }

    set projectTimeline(timeline: string) {
        this.$store.commit(QUOTE_SET_PROJECT_TIMELINE, timeline);
    }

    get isProjectTimelineInvalid(): boolean {
        return this.userSubmittedForm && this.quoteRequested && this.quote.isProjectTimelineValid === false;
    }

    get installationRequired(): boolean {
        return this.quote.installationRequired;
    }

    set installationRequired(req: boolean) {
        this.$store.commit(QUOTE_SET_INSTALLATION_REQUIRED, req);
    }

    get runwayRequired(): boolean {
        return this.quote.runwayRequired;
    }

    set runwayRequired(req: boolean) {
        this.$store.commit(QUOTE_SET_RUNWAY_REQUIRED, req);
    }

    get runwaySpecifications(): string {
        return this.quote.runwaySpecifications;
    }

    set runwaySpecifications(spec: string) {
        this.$store.commit(QUOTE_SET_RUNWAY_SPEC, spec);
    }

    get isRunwaySpecificationsInvalid(): boolean {
        return this.userSubmittedForm && this.quoteRequested && this.quote.isRunwaySpecificationsValid === false;
    }
}
