import { stringNotNullAndHasLength } from "../utils/validation";

export class Quote {
    public name = '';
    public company = '';
    public city = '';
    public state = '';
    public phone = '';
    public email = '';
    public building = '';
    public runwayRequired = false;
    public runwaySpecifications = '';
    public installationRequired = false;
    public projectTimeline = '';

    get isNameValid(): boolean {
        return stringNotNullAndHasLength(this.name);
    }

    get isCompanyValid(): boolean {
        return stringNotNullAndHasLength(this.company);
    }

    get isCityValid(): boolean {
        return stringNotNullAndHasLength(this.city);
    }

    get isStateValid(): boolean {
        return stringNotNullAndHasLength(this.state);
    }

    get isPhoneValid(): boolean {
        return stringNotNullAndHasLength(this.phone);
    }

    get isEmailValid(): boolean {
        return stringNotNullAndHasLength(this.email);
    }

    get isBulidingValid(): boolean {
        return stringNotNullAndHasLength(this.building);
    }

    get isRunwaySpecificationsValid(): boolean {
        if (this.runwayRequired) {
            return stringNotNullAndHasLength(this.runwaySpecifications);
        }
        return true;
    }

    get isProjectTimelineValid(): boolean {
        return stringNotNullAndHasLength(this.projectTimeline);
    }
}
