import { Measurement } from "./crane";

export enum HOIST_TYPES {
    NONE = 'none',
    DOUBLE_GIRDER_TROLLEY_HOIST = 'Double Girder Trolley Hoist',
    MONORAIL_HOIST = 'Monorail Hoist',
}

export class Trolley {
    public speed = '';
    public horsepower = '';
    public brakes = '';
}

export class Dimension {
    public craneB = 0;
    public craneC = 0;
    public headroomC = 0;
    public aboveHeadroomC = 0;
}

export class Hoist {
    public brakes = '';
    public capacity = 0;
    public controlType = '';
    public controlType2 = '';
    public dimension = new Dimension();
    public electrificationSideApproach = 0;
    public hoistType = HOIST_TYPES.NONE;
    public horsepower = '';
    public lift: Measurement = { feet: 0, inch: 0, fractions: '' };
    public manufacturer = '';
    public manufacturerDisplayName = '';
    public model = '';
    public price = 0.00;
    public reeving = '';
    public sideApproach = 0.00;
    public speed = '';
    public trolley = new Trolley();
    public weight = 0;
    public wheelDiameter = 0;

    public static createFromHoistData(data: any): Hoist {
        const hoist = new Hoist();
        hoist.brakes = data.hoistBrakes;
        hoist.capacity = data.hoistCapacity;
        hoist.controlType = data.hoistControlType;
        hoist.controlType2 = data.hoistControlType2;
        hoist.electrificationSideApproach = data.hoistElectrificationSideApproach;
        // Set Hoist Type
        switch (data.hoistType) {
            case HOIST_TYPES.DOUBLE_GIRDER_TROLLEY_HOIST:
                hoist.hoistType = HOIST_TYPES.DOUBLE_GIRDER_TROLLEY_HOIST;
                break;
            case HOIST_TYPES.MONORAIL_HOIST:
                hoist.hoistType = HOIST_TYPES.MONORAIL_HOIST;
                break;
            default:
                hoist.hoistType = HOIST_TYPES.NONE;
        }
        hoist.horsepower = data.hoistHP;
        hoist.lift.feet = data.hoistLiftFeet;
        hoist.lift.inch = data.hoistLiftInch;
        hoist.manufacturer = data.hoistManufacturer;
        hoist.manufacturerDisplayName = data.hoistManufacturerDisplayName;
        hoist.model = data.hoistModel;
        hoist.price = parseFloat(data.hoistPrice.replace('$', '').replace(',', ''));
        hoist.reeving = data.hoistReeving;
        hoist.sideApproach = data.hoistSideApproach;
        hoist.speed = data.hoistSpeed;
        hoist.trolley.brakes = data.hoistTrolleyBrakes;
        hoist.trolley.horsepower = data.hoistTrolleyHP;
        hoist.trolley.speed = data.hoistTrolleySpeed;
        hoist.weight = data.hoistWeight;
        hoist.wheelDiameter = data.hoistWheelDiameter;
        hoist.dimension.headroomC = data.hoistHeadroomC;
        hoist.dimension.aboveHeadroomC = data.hoistAboveHeadroomC;

        return hoist;
    }
}
