import { CraneListing } from "../models/crane";

const cranes = [
   {
      craneWeight: 3712,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "6,528",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 4729,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "6,782",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 6375,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "7,194",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 7442,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "35-0",
      craneSpanFeet: 35,
      craneSpanInch: 0,
      craneWheelLoad: "7,761",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 8690,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "7,773",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 12080,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "8,620",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 13256,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "8,914",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 14433,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "9,208",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 20649,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "10,762",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 20573,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "65-0",
      craneSpanFeet: 65,
      craneSpanInch: 0,
      craneWheelLoad: "10,743",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W32",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-7-1/2\"",
      craneEndApproachBack: "5'-7-1/2\""
   },
   {
      craneWeight: 23326,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "70-0",
      craneSpanFeet: 70,
      craneSpanInch: 0,
      craneWheelLoad: "11,432",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W32",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-7-1/2\"",
      craneEndApproachBack: "5'-7-1/2\""
   },
   {
      craneWeight: 24350,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "80-0",
      craneSpanFeet: 80,
      craneSpanInch: 0,
      craneWheelLoad: "13,644",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W35",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "12'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 30721,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "85-0",
      craneSpanFeet: 85,
      craneSpanInch: 0,
      craneWheelLoad: "13,280",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W38",
      craneFlange: "0",
      craneWheelDiameter: "8-1/4\"",
      craneWheelBase: "12'-5\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 45580,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "95-0",
      craneSpanFeet: 95,
      craneSpanInch: 0,
      craneWheelLoad: "17,145",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W42",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "14'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 61725,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "100-0",
      craneSpanFeet: 100,
      craneSpanInch: 0,
      craneWheelLoad: "26,681",
      craneHpRpm: "6 @ 1800",
      craneBeam: "W46",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "14'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 4392,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "11,948",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 5579,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "12,245",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 6915,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "12,579",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 9262,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "35-0",
      craneSpanFeet: 35,
      craneSpanInch: 0,
      craneWheelLoad: "13,166",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 10930,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "13,583",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 13250,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "14,163",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 15956,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "14,839",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 19053,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "15,613",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 22569,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "16,492",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 18636,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "65-0",
      craneSpanFeet: 65,
      craneSpanInch: 0,
      craneWheelLoad: "15,509",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W35",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-7-1/2\"",
      craneEndApproachBack: "5'-7-1/2\""
   },
   {
      craneWeight: 19980,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "70-0",
      craneSpanFeet: 70,
      craneSpanInch: 0,
      craneWheelLoad: "15,845",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W35",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-7-1/2\"",
      craneEndApproachBack: "5'-7-1/2\""
   },
   {
      craneWeight: 26740,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "75-0",
      craneSpanFeet: 75,
      craneSpanInch: 0,
      craneWheelLoad: "17,435",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W38",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "12'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 28090,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "80-0",
      craneSpanFeet: 80,
      craneSpanInch: 0,
      craneWheelLoad: "17,773",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W38",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "12'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 41060,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "85-0",
      craneSpanFeet: 85,
      craneSpanInch: 0,
      craneWheelLoad: "21,015",
      craneHpRpm: "6 @ 1800",
      craneBeam: "W42",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "14'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 59105,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "95-0",
      craneSpanFeet: 95,
      craneSpanInch: 0,
      craneWheelLoad: "26,026",
      craneHpRpm: "6 @ 1800",
      craneBeam: "W46",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "14'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 63045,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "100-0",
      craneSpanFeet: 100,
      craneSpanInch: 0,
      craneWheelLoad: "16,381",
      craneHpRpm: "6 @ 1800",
      craneBeam: "W46",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "12'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 5396,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "17,599",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-5-1/2\"",
      craneEndApproachBack: "5'-5-1/2\""
   },
   {
      craneWeight: 6633,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "17,908",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-5-1/2\"",
      craneEndApproachBack: "5'-5-1/2\""
   },
   {
      craneWeight: 8779,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "18,445",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-5-1/2\"",
      craneEndApproachBack: "5'-5-1/2\""
   },
   {
      craneWeight: 10356,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "35-0",
      craneSpanFeet: 35,
      craneSpanInch: 0,
      craneWheelLoad: "18,839",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-5-1/2\"",
      craneEndApproachBack: "5'-5-1/2\""
   },
   {
      craneWeight: 12874,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "19,394",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-5-1/2\"",
      craneEndApproachBack: "5'-5-1/2\""
   },
   {
      craneWeight: 15114,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "20,029",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-5-1/2\"",
      craneEndApproachBack: "5'-5-1/2\""
   },
   {
      craneWeight: 19660,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "21,165",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-5-1/2\"",
      craneEndApproachBack: "5'-5-1/2\""
   },
   {
      craneWeight: 19437,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "21,109",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W32",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 21565,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "21,641",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W32",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 23218,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "65-0",
      craneSpanFeet: 65,
      craneSpanInch: 0,
      craneWheelLoad: "22,055",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W32",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 27626,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "70-0",
      craneSpanFeet: 70,
      craneSpanInch: 0,
      craneWheelLoad: "23,157",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W38",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 29717,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "75-0",
      craneSpanFeet: 75,
      craneSpanInch: 0,
      craneWheelLoad: "24,429",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W42",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "12'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 37217,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "80-0",
      craneSpanFeet: 80,
      craneSpanInch: 0,
      craneWheelLoad: "26,304",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W42",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "12'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 5316,
      calcCraneCapacity: 20,
      craneCapacity: "20",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "22,829",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-5-1/2\"",
      craneEndApproachBack: "5'-5-1/2\""
   },
   {
      craneWeight: 7683,
      calcCraneCapacity: 20,
      craneCapacity: "20",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "23,421",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-5-1/2\"",
      craneEndApproachBack: "5'-5-1/2\""
   },
   {
      craneWeight: 9979,
      calcCraneCapacity: 20,
      craneCapacity: "20",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "23,995",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-5-1/2\"",
      craneEndApproachBack: "5'-5-1/2\""
   },
   {
      craneWeight: 12246,
      calcCraneCapacity: 20,
      craneCapacity: "20",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "35-0",
      craneSpanFeet: 35,
      craneSpanInch: 0,
      craneWheelLoad: "24,562",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-5-1/2\"",
      craneEndApproachBack: "5'-5-1/2\""
   },
   {
      craneWeight: 13694,
      calcCraneCapacity: 20,
      craneCapacity: "20",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "24,924",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-5-1/2\"",
      craneEndApproachBack: "5'-5-1/2\""
   },
   {
      craneWeight: 17814,
      calcCraneCapacity: 20,
      craneCapacity: "20",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "25,954",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 18450,
      calcCraneCapacity: 20,
      craneCapacity: "20",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "26,113",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W29",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 23144,
      calcCraneCapacity: 20,
      craneCapacity: "20",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "27,286",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W38",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 25057,
      calcCraneCapacity: 20,
      craneCapacity: "20",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "27,764",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W38",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 26078,
      calcCraneCapacity: 20,
      craneCapacity: "20",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "65-0",
      craneSpanFeet: 65,
      craneSpanInch: 0,
      craneWheelLoad: "28,020",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W38",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5-1/4\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 26765,
      calcCraneCapacity: 20,
      craneCapacity: "20",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "70-0",
      craneSpanFeet: 70,
      craneSpanInch: 0,
      craneWheelLoad: "29,191",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W38",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 35975,
      calcCraneCapacity: 20,
      craneCapacity: "20",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "75-0",
      craneSpanFeet: 75,
      craneSpanInch: 0,
      craneWheelLoad: "31,494",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W42",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "12'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 43725,
      calcCraneCapacity: 20,
      craneCapacity: "20",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "80-0",
      craneSpanFeet: 80,
      craneSpanInch: 0,
      craneWheelLoad: "33,431",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W42",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "12'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 63380,
      calcCraneCapacity: 25,
      craneCapacity: "25",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "100-0",
      craneSpanFeet: 100,
      craneSpanInch: 0,
      craneWheelLoad: "21,673",
      craneHpRpm: "8 @ 1800",
      craneBeam: "W46",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "12'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 6582,
      calcCraneCapacity: 25,
      craneCapacity: "25",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "28,646",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 9509,
      calcCraneCapacity: 25,
      craneCapacity: "25",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "29,377",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 11645,
      calcCraneCapacity: 25,
      craneCapacity: "25",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "29,911",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 13072,
      calcCraneCapacity: 25,
      craneCapacity: "25",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "35-0",
      craneSpanFeet: 35,
      craneSpanInch: 0,
      craneWheelLoad: "30,268",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 15720,
      calcCraneCapacity: 25,
      craneCapacity: "25",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "30,930",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 18613,
      calcCraneCapacity: 25,
      craneCapacity: "25",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "31,653",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W35",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 22876,
      calcCraneCapacity: 25,
      craneCapacity: "25",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "32,719",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W35",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 24872,
      calcCraneCapacity: 25,
      craneCapacity: "25",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "33,218",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W35",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 29519,
      calcCraneCapacity: 25,
      craneCapacity: "25",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "34,380",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W38",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 36172,
      calcCraneCapacity: 25,
      craneCapacity: "25",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "70-0",
      craneSpanFeet: 70,
      craneSpanInch: 0,
      craneWheelLoad: "36,543",
      craneHpRpm: "6 @ 1800",
      craneBeam: "W42",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 57630,
      calcCraneCapacity: 25,
      craneCapacity: "25",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "90-0",
      craneSpanFeet: 90,
      craneSpanInch: 0,
      craneWheelLoad: "20,954",
      craneHpRpm: "8 @ 1800",
      craneBeam: "W44",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "12'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 7618,
      calcCraneCapacity: 30,
      craneCapacity: "30",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "34,155",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 8754,
      calcCraneCapacity: 30,
      craneCapacity: "30",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "34,439",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 9899,
      calcCraneCapacity: 30,
      craneCapacity: "30",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "34,725",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 12771,
      calcCraneCapacity: 30,
      craneCapacity: "30",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "35-0",
      craneSpanFeet: 35,
      craneSpanInch: 0,
      craneWheelLoad: "35,443",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W29",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 14672,
      calcCraneCapacity: 30,
      craneCapacity: "30",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "35,918",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W32",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 18614,
      calcCraneCapacity: 30,
      craneCapacity: "30",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "36,903",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W35",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 23566,
      calcCraneCapacity: 30,
      craneCapacity: "30",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "38,139",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W38",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 27303,
      calcCraneCapacity: 30,
      craneCapacity: "30",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "39,076",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W38",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 29619,
      calcCraneCapacity: 30,
      craneCapacity: "30",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "39,630",
      craneHpRpm: "10 @ 1800",
      craneBeam: "W38",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "5\"",
      craneEndApproachFront: "5'-11-1/2\"",
      craneEndApproachBack: "5'-11-1/2\""
   },
   {
      craneWeight: 49380,
      calcCraneCapacity: 30,
      craneCapacity: "30",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "75-0",
      craneSpanFeet: 75,
      craneSpanInch: 0,
      craneWheelLoad: "22,423",
      craneHpRpm: "6 @ 1800",
      craneBeam: "W42",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "12'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 55740,
      calcCraneCapacity: 30,
      craneCapacity: "30",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "80-0",
      craneSpanFeet: 80,
      craneSpanInch: 0,
      craneWheelLoad: "23,218",
      craneHpRpm: "6 @ 1800",
      craneBeam: "W42",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "12'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 22390,
      calcCraneCapacity: 35,
      craneCapacity: "35",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "21,549",
      craneHpRpm: "6 @ 1800",
      craneBeam: "W38",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "12'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 28290,
      calcCraneCapacity: 35,
      craneCapacity: "35",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "22,286",
      craneHpRpm: "6 @ 1800",
      craneBeam: "W38",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "12'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 33043,
      calcCraneCapacity: 35,
      craneCapacity: "35",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "65-0",
      craneSpanFeet: 65,
      craneSpanInch: 0,
      craneWheelLoad: "22,880",
      craneHpRpm: "6 @ 1800",
      craneBeam: "W42",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "12'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 40880,
      calcCraneCapacity: 35,
      craneCapacity: "35",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "75-0",
      craneSpanFeet: 75,
      craneSpanInch: 0,
      craneWheelLoad: "23,680",
      craneHpRpm: "6 @ 1800",
      craneBeam: "W42",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "12'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 9387,
      calcCraneCapacity: 40,
      craneCapacity: "40",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "21,548",
      craneHpRpm: "6 @ 1800",
      craneBeam: "W29",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "12'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 17090,
      calcCraneCapacity: 40,
      craneCapacity: "40",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "35-0",
      craneSpanFeet: 35,
      craneSpanInch: 0,
      craneWheelLoad: "26,261",
      craneHpRpm: "6 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "10\"",
      craneWheelBase: "12'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 28840,
      calcCraneCapacity: 40,
      craneCapacity: "40",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "24,855",
      craneHpRpm: "20 @ 1800",
      craneBeam: "W42",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "13'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 31966,
      calcCraneCapacity: 40,
      craneCapacity: "40",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "25,246",
      craneHpRpm: "20 @ 1800",
      craneBeam: "W40",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "13'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 52670,
      calcCraneCapacity: 40,
      craneCapacity: "40",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "75-0",
      craneSpanFeet: 75,
      craneSpanInch: 0,
      craneWheelLoad: "27,834",
      craneHpRpm: "20 @ 1800",
      craneBeam: "W46",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "13'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 61510,
      calcCraneCapacity: 40,
      craneCapacity: "40",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "80-0",
      craneSpanFeet: 80,
      craneSpanInch: 0,
      craneWheelLoad: "28,939",
      craneHpRpm: "20 @ 1800",
      craneBeam: "W46",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "13'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 57420,
      calcCraneCapacity: 40,
      craneCapacity: "40",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "90-0",
      craneSpanFeet: 90,
      craneSpanInch: 0,
      craneWheelLoad: "28,428",
      craneHpRpm: "20 @ 1800",
      craneBeam: "B75",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "13'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 17782,
      calcCraneCapacity: 50,
      craneCapacity: "50",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "28,473",
      craneHpRpm: "20 @ 1800",
      craneBeam: "W35",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "13'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 75347,
      calcCraneCapacity: 50,
      craneCapacity: "50",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "80-0",
      craneSpanFeet: 80,
      craneSpanInch: 0,
      craneWheelLoad: "37,418",
      craneHpRpm: "20 @ 1800",
      craneBeam: "W46",
      craneFlange: "0",
      craneWheelDiameter: "15\"",
      craneWheelBase: "13'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 50050,
      calcCraneCapacity: 55,
      craneCapacity: "55",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "70-0",
      craneSpanFeet: 70,
      craneSpanInch: 0,
      craneWheelLoad: "27,506",
      craneHpRpm: "20 @ 1800",
      craneBeam: "W46",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "13'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 34072,
      calcCraneCapacity: 60,
      craneCapacity: "60",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "35,509",
      craneHpRpm: "20 @ 1800",
      craneBeam: "W42",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "13'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 39472,
      calcCraneCapacity: 60,
      craneCapacity: "60",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "37,434",
      craneHpRpm: "20 @ 1800",
      craneBeam: "W42",
      craneFlange: "0",
      craneWheelDiameter: "12\"",
      craneWheelBase: "13'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 35104,
      calcCraneCapacity: 75,
      craneCapacity: "75",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "44,888",
      craneHpRpm: "15 @ 3600",
      craneBeam: "W42",
      craneFlange: "0",
      craneWheelDiameter: "15\"",
      craneWheelBase: "13'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 68845,
      calcCraneCapacity: 75,
      craneCapacity: "75",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "80-0",
      craneSpanFeet: 80,
      craneSpanInch: 0,
      craneWheelLoad: "49,106",
      craneHpRpm: "30 @ 1800",
      craneBeam: "W62",
      craneFlange: "0",
      craneWheelDiameter: "15\"",
      craneWheelBase: "13'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 40765,
      calcCraneCapacity: 80,
      craneCapacity: "80",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "48,096",
      craneHpRpm: "15 @ 3600",
      craneBeam: "W42",
      craneFlange: "0",
      craneWheelDiameter: "15\"",
      craneWheelBase: "13'-0\" (2@ 4'-6\")",
      craneElectrificationSideApproach: 5.5,
      craneSideApproach: 5.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 4112,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "9,278",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 5079,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "9,520",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 6615,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "9,904",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 7792,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "35-0",
      craneSpanFeet: 35,
      craneSpanInch: 0,
      craneWheelLoad: "10,198",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 10370,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "10,843",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 12081,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "11,270",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 14566,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "11,889",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 17403,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "12,601",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 20650,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "13,412",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "4-15/16\"",
      craneEndApproachFront: "5'-1-1/2\"",
      craneEndApproachBack: "5'-1-1/2\""
   },
   {
      craneWeight: 18637,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "65-0",
      craneSpanFeet: 65,
      craneSpanInch: 0,
      craneWheelLoad: "12,909",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W35",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 19981,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "70-0",
      craneSpanFeet: 70,
      craneSpanInch: 0,
      craneWheelLoad: "13,245",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W35",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 33420,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "85-0",
      craneSpanFeet: 85,
      craneSpanInch: 0,
      craneWheelLoad: "16,605",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W38",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "13'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 3188,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "4,397",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-3/8\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 4365,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "4,691",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-3/8\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 4991,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "4,848",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-3/8\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 6798,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "35-0",
      craneSpanFeet: 35,
      craneSpanInch: 0,
      craneWheelLoad: "5,300",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-3/8\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 8326,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "5,682",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-3/8\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 9196,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "5,899",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-3/8\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 12192,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "7,217",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-3/8\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 14269,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "56-0",
      craneSpanFeet: 56,
      craneSpanInch: 0,
      craneWheelLoad: "7,217",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-3/8\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 3818,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "7,040",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-3/8\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 4835,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "7,294",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-3/8\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 6481,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "7,705",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-3/8\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 7268,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "35-0",
      craneSpanFeet: 35,
      craneSpanInch: 0,
      craneWheelLoad: "7,902",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-3/8\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 8796,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "8,284",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-3/8\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 12186,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "9,132",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-3/8\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 13362,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "9,426",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-3/8\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 14867,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "56-0",
      craneSpanFeet: 56,
      craneSpanInch: 0,
      craneWheelLoad: "9,852",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.5,
      craneSideApproach: 3.5,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-3/8\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 5032,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "12,583",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-1/2\"",
      craneEndApproachFront: "4'-10-1/4\"",
      craneEndApproachBack: "4'-10-1/4\""
   },
   {
      craneWeight: 5819,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "12,780",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-1/2\"",
      craneEndApproachFront: "4'-10-1/4\"",
      craneEndApproachBack: "4'-10-1/4\""
   },
   {
      craneWeight: 7173,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "13,118",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-1/2\"",
      craneEndApproachFront: "4'-10-1/4\"",
      craneEndApproachBack: "4'-10-1/4\""
   },
   {
      craneWeight: 9562,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "35-0",
      craneSpanFeet: 35,
      craneSpanInch: 0,
      craneWheelLoad: "13,716",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-1/2\"",
      craneEndApproachFront: "4'-10-1/4\"",
      craneEndApproachBack: "4'-10-1/4\""
   },
   {
      craneWeight: 11244,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "14,136",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-1/2\"",
      craneEndApproachFront: "4'-10-1/4\"",
      craneEndApproachBack: "4'-10-1/4\""
   },
   {
      craneWeight: 13590,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "14,723",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-1/2\"",
      craneEndApproachFront: "4'-10-1/4\"",
      craneEndApproachBack: "4'-10-1/4\""
   },
   {
      craneWeight: 16324,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "15,406",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-1/2\"",
      craneEndApproachFront: "4'-10-1/4\"",
      craneEndApproachBack: "4'-10-1/4\""
   },
   {
      craneWeight: 19751,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "56-0",
      craneSpanFeet: 56,
      craneSpanInch: 0,
      craneWheelLoad: "16,288",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-1/2\"",
      craneEndApproachFront: "4'-10-1/4\"",
      craneEndApproachBack: "4'-10-1/4\""
   },
   {
      craneWeight: 4218,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "9,880",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-1/2\"",
      craneEndApproachFront: "4'-10-1/4\"",
      craneEndApproachBack: "4'-10-1/4\""
   },
   {
      craneWeight: 5685,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "10,246",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W18",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-1/2\"",
      craneEndApproachFront: "4'-10-1/4\"",
      craneEndApproachBack: "4'-10-1/4\""
   },
   {
      craneWeight: 7021,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "10,580",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-1/2\"",
      craneEndApproachFront: "4'-10-1/4\"",
      craneEndApproachBack: "4'-10-1/4\""
   },
   {
      craneWeight: 7898,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "35-0",
      craneSpanFeet: 35,
      craneSpanInch: 0,
      craneWheelLoad: "10,800",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-1/2\"",
      craneEndApproachFront: "4'-10-1/4\"",
      craneEndApproachBack: "4'-10-1/4\""
   },
   {
      craneWeight: 10476,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "11,444",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W20",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-1/2\"",
      craneEndApproachFront: "4'-10-1/4\"",
      craneEndApproachBack: "4'-10-1/4\""
   },
   {
      craneWeight: 12187,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "11,872",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-1/2\"",
      craneEndApproachFront: "4'-10-1/4\"",
      craneEndApproachBack: "4'-10-1/4\""
   },
   {
      craneWeight: 14662,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "12,941",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-1/2\"",
      craneEndApproachFront: "4'-10-1/4\"",
      craneEndApproachBack: "4'-10-1/4\""
   },
   {
      craneWeight: 17237,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Double Girder Top Running - SA",
      craneBaseType: "dgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "56-0",
      craneSpanFeet: 56,
      craneSpanInch: 0,
      craneWheelLoad: "13,334",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W26",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-0\"",
      craneElectrificationSideApproach: 3.75,
      craneSideApproach: 3.75,
      craneDistanceAboveRail: 0,
      craneOverhang: "3-1/2\"",
      craneEndApproachFront: "4'-10-1/4\"",
      craneEndApproachBack: "4'-10-1/4\""
   },
   {
      craneWeight: 1657,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "15-0",
      craneSpanFeet: 15,
      craneSpanInch: 0,
      craneWheelLoad: "2,617",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W12",
      craneFlange: "6-1/2\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 1845,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "2,664",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W12",
      craneFlange: "6-1/2\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 2172,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "2,746",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W12",
      craneFlange: "8\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 2540,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "2,838",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W12",
      craneFlange: "8\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 3140,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "36-0",
      craneSpanFeet: 36,
      craneSpanInch: 0,
      craneWheelLoad: "2,988",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W14",
      craneFlange: "8\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 3846,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "3,164",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W18",
      craneFlange: "7-1/2\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 4197,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "3,252",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W18",
      craneFlange: "7-1/2\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 4548,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "3,340",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W18",
      craneFlange: "7-1/2\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 5610,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "3,605",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W21",
      craneFlange: "8-1/4\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-8-1/2\"",
      craneEndApproachBack: "4'-8-1/2\""
   },
   {
      craneWeight: 6062,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "3,718",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W21",
      craneFlange: "8-1/4\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-8-1/2\"",
      craneEndApproachBack: "4'-8-1/2\""
   },
   {
      craneWeight: 8055,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "65-0",
      craneSpanFeet: 65,
      craneSpanInch: 0,
      craneWheelLoad: "4,216",
      craneHpRpm: "1.5 @ 1800",
      craneBeam: "W24",
      craneFlange: "9\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-10-1/2\"",
      craneEndApproachBack: "4'-10-1/2\""
   },
   {
      craneWeight: 12973,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "80-0",
      craneSpanFeet: 80,
      craneSpanInch: 0,
      craneWheelLoad: "5,743",
      craneHpRpm: "2 @ 1800",
      craneBeam: "W33",
      craneFlange: "11-1/2\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 1658,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "15-0",
      craneSpanFeet: 15,
      craneSpanInch: 0,
      craneWheelLoad: "3,704",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W12",
      craneFlange: "6-1/2\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 1846,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "3,752",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W12",
      craneFlange: "6-1/2\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 2380,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "3,885",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W14",
      craneFlange: "8\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 2788,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "3,987",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W14",
      craneFlange: "8\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 3658,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "36-0",
      craneSpanFeet: 36,
      craneSpanInch: 0,
      craneWheelLoad: "4,204",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W16",
      craneFlange: "10-1/4\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 4407,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "4,391",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W16",
      craneFlange: "10-1/4\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 4749,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "4,477",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W21",
      craneFlange: "8-1/4\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 5160,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "4,580",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W21",
      craneFlange: "8-1/4\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 5946,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "4,777",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W21",
      craneFlange: "8-1/4\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-8-1/2\"",
      craneEndApproachBack: "4'-8-1/2\""
   },
   {
      craneWeight: 7438,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "5,149",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W24",
      craneFlange: "9\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-8-1/2\"",
      craneEndApproachBack: "4'-8-1/2\""
   },
   {
      craneWeight: 8623,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "65-0",
      craneSpanFeet: 65,
      craneSpanInch: 0,
      craneWheelLoad: "5,446",
      craneHpRpm: "1.5 @ 1800",
      craneBeam: "W27",
      craneFlange: "10\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-10-1/2\"",
      craneEndApproachBack: "4'-10-1/2\""
   },
   {
      craneWeight: 9074,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "70-0",
      craneSpanFeet: 70,
      craneSpanInch: 0,
      craneWheelLoad: "5,769",
      craneHpRpm: "2 @ 1800",
      craneBeam: "W27",
      craneFlange: "10\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 25645,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "100-0",
      craneSpanFeet: 100,
      craneSpanInch: 0,
      craneWheelLoad: "11,911",
      craneHpRpm: "2 @ 1800",
      craneBeam: "B54",
      craneFlange: "18\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "13'-0\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 1992,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "15-0",
      craneSpanFeet: 15,
      craneSpanInch: 0,
      craneWheelLoad: "5,928",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W18",
      craneFlange: "7-1/2\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 2385,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "6,026",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W18",
      craneFlange: "7-5/8\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 2993,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "6,178",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W18",
      craneFlange: "7-5/8\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 3454,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "6,294",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W14",
      craneFlange: "10\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 4487,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "36-0",
      craneSpanFeet: 36,
      craneSpanInch: 0,
      craneWheelLoad: "6,552",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W16",
      craneFlange: "10-3/8\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 4959,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "6,670",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W16",
      craneFlange: "10-3/8\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 18,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 6218,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "6,985",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W18",
      craneFlange: "11-1/4\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 18,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 8056,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "7,444",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W24",
      craneFlange: "12-7/8\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 18,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 7942,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "7,415",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W24",
      craneFlange: "9\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 18,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-8-1/2\"",
      craneEndApproachBack: "4'-8-1/2\""
   },
   {
      craneWeight: 8596,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "7,579",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W24",
      craneFlange: "9\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 18,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-8-1/2\"",
      craneEndApproachBack: "4'-8-1/2\""
   },
   {
      craneWeight: 11719,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "65-0",
      craneSpanFeet: 65,
      craneSpanInch: 0,
      craneWheelLoad: "8,359",
      craneHpRpm: "1.5 @ 1800",
      craneBeam: "W33",
      craneFlange: "11-1/2\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "8'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 18,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-10-1/2\"",
      craneEndApproachBack: "4'-10-1/2\""
   },
   {
      craneWeight: 12294,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "70-0",
      craneSpanFeet: 70,
      craneSpanInch: 0,
      craneWheelLoad: "9,074",
      craneHpRpm: "2 @ 1800",
      craneBeam: "W33",
      craneFlange: "11-1/2\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 18,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 11948,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "75-0",
      craneSpanFeet: 75,
      craneSpanInch: 0,
      craneWheelLoad: "8,987",
      craneHpRpm: "2 @ 1800",
      craneBeam: "W30",
      craneFlange: "10-1/2\"",
      craneWheelDiameter: "6\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 18,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 2860,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "15-0",
      craneSpanFeet: 15,
      craneSpanInch: 0,
      craneWheelLoad: "11,640",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W21",
      craneFlange: "8-3/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 27,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 3884,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "11,716",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W18",
      craneFlange: "11-1/4\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 4501,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "11,870",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W18",
      craneFlange: "11-1/4\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 5512,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "12,123",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W21",
      craneFlange: "12-1/2\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 6301,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "36-0",
      craneSpanFeet: 36,
      craneSpanInch: 0,
      craneWheelLoad: "12,320",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W24",
      craneFlange: "12-7/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 26,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 7600,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "12,645",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W24",
      craneFlange: "12-7/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 8363,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "12,836",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W24",
      craneFlange: "12-7/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 9530,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "13,127",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W30",
      craneFlange: "10-1/2\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 11555,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "13,634",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W33",
      craneFlange: "11-5/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-8-1/2\"",
      craneEndApproachBack: "4'-8-1/2\""
   },
   {
      craneWeight: 12907,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "13,972",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W33",
      craneFlange: "11-5/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-8-1/2\"",
      craneEndApproachBack: "4'-8-1/2\""
   },
   {
      craneWeight: 13622,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "65-0",
      craneSpanFeet: 65,
      craneSpanInch: 0,
      craneWheelLoad: "14,405",
      craneHpRpm: "2 @ 1200",
      craneBeam: "W36",
      craneFlange: "12\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-8-1/2\"",
      craneEndApproachBack: "4'-8-1/2\""
   },
   {
      craneWeight: 14448,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "70-0",
      craneSpanFeet: 70,
      craneSpanInch: 0,
      craneWheelLoad: "14,622",
      craneHpRpm: "3 @ 1800",
      craneBeam: "W36",
      craneFlange: "12\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "9'-0\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 15452,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "75-0",
      craneSpanFeet: 75,
      craneSpanInch: 0,
      craneWheelLoad: "14,683",
      craneHpRpm: "3 @ 1800",
      craneBeam: "W36",
      craneFlange: "12\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 16415,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "80-0",
      craneSpanFeet: 80,
      craneSpanInch: 0,
      craneWheelLoad: "15,104",
      craneHpRpm: "3 @ 1800",
      craneBeam: "W36",
      craneFlange: "12\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 3960,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "15-0",
      craneSpanFeet: 15,
      craneSpanInch: 0,
      craneWheelLoad: "16,880",
      craneHpRpm: "3.5 @ 1800",
      craneBeam: "W18",
      craneFlange: "11-1/8\"",
      craneWheelDiameter: "8-1/4\"",
      craneWheelBase: "7'-1\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1-3/4\"",
      craneEndApproachBack: "4'-1-3/4\""
   },
   {
      craneWeight: 4675,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "17,059",
      craneHpRpm: "3.5 @ 1800",
      craneBeam: "W18",
      craneFlange: "11-1/8\"",
      craneWheelDiameter: "8-1/4\"",
      craneWheelBase: "7'-1\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1-3/4\"",
      craneEndApproachBack: "4'-1-3/4\""
   },
   {
      craneWeight: 5390,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "17,237",
      craneHpRpm: "3.5 @ 1800",
      craneBeam: "W18",
      craneFlange: "11-1/8\"",
      craneWheelDiameter: "8-1/4\"",
      craneWheelBase: "7'-1\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1-3/4\"",
      craneEndApproachBack: "4'-1-3/4\""
   },
   {
      craneWeight: 6533,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "17,524",
      craneHpRpm: "3.5 @ 1800",
      craneBeam: "W18",
      craneFlange: "11-1/4\"",
      craneWheelDiameter: "8-1/4\"",
      craneWheelBase: "7'-1\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1-3/4\"",
      craneEndApproachBack: "4'-1-3/4\""
   },
   {
      craneWeight: 8047,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "35-0",
      craneSpanFeet: 35,
      craneSpanInch: 0,
      craneWheelLoad: "17,901",
      craneHpRpm: "3.5 @ 1800",
      craneBeam: "W24",
      craneFlange: "13\"",
      craneWheelDiameter: "8-1/4\"",
      craneWheelBase: "7'-1\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1-3/4\"",
      craneEndApproachBack: "4'-1-3/4\""
   },
   {
      craneWeight: 9111,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "18,168",
      craneHpRpm: "3.5 @ 1800",
      craneBeam: "W24",
      craneFlange: "13\"",
      craneWheelDiameter: "8-1/4\"",
      craneWheelBase: "7'-1\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1-3/4\"",
      craneEndApproachBack: "4'-1-3/4\""
   },
   {
      craneWeight: 10353,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "18,474",
      craneHpRpm: "3.5 @ 1800",
      craneBeam: "W30",
      craneFlange: "15\"",
      craneWheelDiameter: "8-1/4\"",
      craneWheelBase: "7'-1\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1-3/4\"",
      craneEndApproachBack: "4'-1-3/4\""
   },
   {
      craneWeight: 11283,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "18,710",
      craneHpRpm: "3.5 @ 1800",
      craneBeam: "W30",
      craneFlange: "15\"",
      craneWheelDiameter: "8-1/4\"",
      craneWheelBase: "7'-1\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1-3/4\"",
      craneEndApproachBack: "4'-1-3/4\""
   },
   {
      craneWeight: 14727,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "19,572",
      craneHpRpm: "3.5 @ 1800",
      craneBeam: "W30",
      craneFlange: "15\"",
      craneWheelDiameter: "8-1/4\"",
      craneWheelBase: "7'-1\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1-3/4\"",
      craneEndApproachBack: "4'-1-3/4\""
   },
   {
      craneWeight: 13319,
      calcCraneCapacity: 15,
      craneCapacity: "15",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "19,330",
      craneHpRpm: "4 @ 1800",
      craneBeam: "W36",
      craneFlange: "12\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-8-1/2\"",
      craneEndApproachBack: "4'-8-1/2\""
   },
   {
      craneWeight: 2508,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "15-0",
      craneSpanFeet: 15,
      craneSpanInch: 0,
      craneWheelLoad: "8,872",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W18",
      craneFlange: "7-5/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 25,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 2876,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "8,964",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W18",
      craneFlange: "7-5/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 25,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 3565,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "9,136",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W21",
      craneFlange: "8-3/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 25,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 4179,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "9,290",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W16",
      craneFlange: "10-3/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 25,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 5376,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "36-0",
      craneSpanFeet: 36,
      craneSpanInch: 0,
      craneWheelLoad: "9,589",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W18",
      craneFlange: "11-1/4\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 25,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "3'-2\"",
      craneEndApproachBack: "3'-2\""
   },
   {
      craneWeight: 6494,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "9,869",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W18",
      craneFlange: "11-1/4\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 22,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 7719,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "10,175",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W21",
      craneFlange: "12-1/2\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 22,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 9126,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "10,527",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W24",
      craneFlange: "12-7/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 22,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 10434,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "10,853",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W30",
      craneFlange: "12-1/2\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-8-1/2\"",
      craneEndApproachBack: "4'-8-1/2\""
   },
   {
      craneWeight: 11688,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "11,166",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W30",
      craneFlange: "12-1/2\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "4'-8-1/2\"",
      craneEndApproachBack: "4'-8-1/2\""
   },
   {
      craneWeight: 12387,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "65-0",
      craneSpanFeet: 65,
      craneSpanInch: 0,
      craneWheelLoad: "11,157",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W33",
      craneFlange: "11-1/2\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-3\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "4\"",
      craneEndApproachFront: "5'-1\"",
      craneEndApproachBack: "5'-1\""
   },
   {
      craneWeight: 16416,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Top Running",
      craneBaseType: "sgtrdm",
      cranePrice: "$0",
      calcCraneSpan: "80-0",
      craneSpanFeet: 80,
      craneSpanInch: 0,
      craneWheelLoad: "12,604",
      craneHpRpm: "3 @ 1800",
      craneBeam: "W36",
      craneFlange: "12\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "10'-0\"",
      craneElectrificationSideApproach: 27,
      craneSideApproach: 12,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 1497,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "15-0",
      craneSpanFeet: 15,
      craneSpanInch: 0,
      craneWheelLoad: "2,577",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W13",
      craneFlange: "6-1/2\"",
      craneWheelDiameter: "5\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'",
      craneEndApproachBack: "3'"
   },
   {
      craneWeight: 1685,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "2,624",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W13",
      craneFlange: "6-1/2\"",
      craneWheelDiameter: "5\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'",
      craneEndApproachBack: "3'"
   },
   {
      craneWeight: 2017,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "2,707",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W13",
      craneFlange: "8\"",
      craneWheelDiameter: "5\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'",
      craneEndApproachBack: "3'"
   },
   {
      craneWeight: 2390,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "2,800",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W13",
      craneFlange: "8\"",
      craneWheelDiameter: "5\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'",
      craneEndApproachBack: "3'"
   },
   {
      craneWeight: 2998,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "36-0",
      craneSpanFeet: 36,
      craneSpanInch: 0,
      craneWheelLoad: "2,952",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W15",
      craneFlange: "8\"",
      craneWheelDiameter: "5\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'",
      craneEndApproachBack: "3'"
   },
   {
      craneWeight: 3671,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "3,120",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W19",
      craneFlange: "7-1/2\"",
      craneWheelDiameter: "5\"",
      craneWheelBase: "5'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-5\"",
      craneEndApproachBack: "3'-5\""
   },
   {
      craneWeight: 4207,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "44-0",
      craneSpanFeet: 44,
      craneSpanInch: 0,
      craneWheelLoad: "3,254",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W19",
      craneFlange: "7-1/2\"",
      craneWheelDiameter: "5\"",
      craneWheelBase: "5'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-5\"",
      craneEndApproachBack: "3'-5\""
   },
   {
      craneWeight: 4559,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "3,342",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W19",
      craneFlange: "7-1/2\"",
      craneWheelDiameter: "6-1/2\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 5632,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "3,611",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W22",
      craneFlange: "8-1/4\"",
      craneWheelDiameter: "6-1/2\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 6084,
      calcCraneCapacity: 2,
      craneCapacity: "2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "3,723",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W22",
      craneFlange: "8-1/4\"",
      craneWheelDiameter: "6-1/2\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 1498,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "15-0",
      craneSpanFeet: 15,
      craneSpanInch: 0,
      craneWheelLoad: "3,664",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W11",
      craneFlange: "8\"",
      craneWheelDiameter: "5\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'",
      craneEndApproachBack: "3'"
   },
   {
      craneWeight: 1686,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "3,711",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W11",
      craneFlange: "8\"",
      craneWheelDiameter: "5\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'",
      craneEndApproachBack: "3'"
   },
   {
      craneWeight: 2233,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "3,848",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W15",
      craneFlange: "8\"",
      craneWheelDiameter: "5\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'",
      craneEndApproachBack: "3'"
   },
   {
      craneWeight: 2646,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "3,952",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W15",
      craneFlange: "8\"",
      craneWheelDiameter: "5\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'",
      craneEndApproachBack: "3'"
   },
   {
      craneWeight: 3530,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "36-0",
      craneSpanFeet: 36,
      craneSpanInch: 0,
      craneWheelLoad: "4,172",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W17",
      craneFlange: "10-1/4\"",
      craneWheelDiameter: "5\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'",
      craneEndApproachBack: "3'"
   },
   {
      craneWeight: 4259,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "4,355",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W22",
      craneFlange: "8-3/8\"",
      craneWheelDiameter: "5\"",
      craneWheelBase: "5'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-5\"",
      craneEndApproachBack: "3'-5\""
   },
   {
      craneWeight: 4771,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "44-0",
      craneSpanFeet: 44,
      craneSpanInch: 0,
      craneWheelLoad: "4,483",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W22",
      craneFlange: "8-1/4\"",
      craneWheelDiameter: "5\"",
      craneWheelBase: "5'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-5\"",
      craneEndApproachBack: "3'-5\""
   },
   {
      craneWeight: 5182,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "4,483",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W22",
      craneFlange: "8-1/4\"",
      craneWheelDiameter: "6-1/2\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 5974,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "4,784",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W22",
      craneFlange: "8-1/4\"",
      craneWheelDiameter: "6-1/2\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 8006,
      calcCraneCapacity: 3,
      craneCapacity: "3",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "5,291",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W22",
      craneFlange: "8-3/8\"",
      craneWheelDiameter: "6-1/2\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 2057,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "15-0",
      craneSpanFeet: 15,
      craneSpanInch: 0,
      craneWheelLoad: "5,944",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W19",
      craneFlange: "7-1/2\"",
      craneWheelDiameter: "6-1/2\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-1\"",
      craneEndApproachBack: "3'-1\""
   },
   {
      craneWeight: 2455,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "6,044",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W19",
      craneFlange: "7-5/8\"",
      craneWheelDiameter: "6-1/2\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-1\"",
      craneEndApproachBack: "3'-1\""
   },
   {
      craneWeight: 3074,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "6,200",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W19",
      craneFlange: "7-5/8\"",
      craneWheelDiameter: "6-1/2\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-1\"",
      craneEndApproachBack: "3'-1\""
   },
   {
      craneWeight: 3538,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "6,315",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W15",
      craneFlange: "10\"",
      craneWheelDiameter: "6-1/2\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-1\"",
      craneEndApproachBack: "3'-1\""
   },
   {
      craneWeight: 4586,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "36-0",
      craneSpanFeet: 36,
      craneSpanInch: 0,
      craneWheelLoad: "6,577",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W17",
      craneFlange: "10-3/8\"",
      craneWheelDiameter: "6-1/2\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-1\"",
      craneEndApproachBack: "3'-1\""
   },
   {
      craneWeight: 5028,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "6,687",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W17",
      craneFlange: "10-3/8\"",
      craneWheelDiameter: "6-1/2\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'",
      craneEndApproachBack: "4'"
   },
   {
      craneWeight: 6304,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "7,006",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W19",
      craneFlange: "11-1/4\"",
      craneWheelDiameter: "6-1/2\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'",
      craneEndApproachBack: "4'"
   },
   {
      craneWeight: 8167,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "7,472",
      craneHpRpm: "2/3 @ 1200",
      craneBeam: "W25",
      craneFlange: "12-7/8\"",
      craneWheelDiameter: "6-1/2\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'",
      craneEndApproachBack: "4'"
   },
   {
      craneWeight: 8016,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "55-0",
      craneSpanFeet: 55,
      craneSpanInch: 0,
      craneWheelLoad: "7,434",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W25",
      craneFlange: "9\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 8608,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "7,582",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W25",
      craneFlange: "9\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'-9\"",
      craneEndApproachBack: "4'-9\""
   },
   {
      craneWeight: 3138,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "15-0",
      craneSpanFeet: 15,
      craneSpanInch: 0,
      craneWheelLoad: "11,530",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W22",
      craneFlange: "8-3/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-3\"",
      craneEndApproachBack: "3'-3\""
   },
   {
      craneWeight: 4188,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "11,792",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W19",
      craneFlange: "11-1/4\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-3\"",
      craneEndApproachBack: "3'-3\""
   },
   {
      craneWeight: 4805,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "11,946",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W19",
      craneFlange: "11-1/4\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-3\"",
      craneEndApproachBack: "3'-3\""
   },
   {
      craneWeight: 5414,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "12,098",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W19",
      craneFlange: "12-1/2\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-3\"",
      craneEndApproachBack: "3'-3\""
   },
   {
      craneWeight: 6617,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "36-0",
      craneSpanFeet: 36,
      craneSpanInch: 0,
      craneWheelLoad: "12,400",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W25",
      craneFlange: "12-7/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-3\"",
      craneEndApproachBack: "3'-3\""
   },
   {
      craneWeight: 8317,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "12,824",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W25",
      craneFlange: "12-7/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 9080,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "13,015",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W25",
      craneFlange: "12-7/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 10232,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "13,303",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W31",
      craneFlange: "10-1/2\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 13649,
      calcCraneCapacity: 10,
      craneCapacity: "10",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "14,142",
      craneHpRpm: "6 @ 1800",
      craneBeam: "W34",
      craneFlange: "11-5/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 7,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 2764,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "15-0",
      craneSpanFeet: 15,
      craneSpanInch: 0,
      craneWheelLoad: "8,936",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W19",
      craneFlange: "7-5/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 4,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-3\"",
      craneEndApproachBack: "3'-3\""
   },
   {
      craneWeight: 3132,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "20-0",
      craneSpanFeet: 20,
      craneSpanInch: 0,
      craneWheelLoad: "9,028",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W19",
      craneFlange: "7-5/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 4,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-3\"",
      craneEndApproachBack: "3'-3\""
   },
   {
      craneWeight: 3833,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "25-0",
      craneSpanFeet: 25,
      craneSpanInch: 0,
      craneWheelLoad: "9,203",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W22",
      craneFlange: "8-3/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 4,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-3\"",
      craneEndApproachBack: "3'-3\""
   },
   {
      craneWeight: 4453,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "30-0",
      craneSpanFeet: 30,
      craneSpanInch: 0,
      craneWheelLoad: "9,358",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W17",
      craneFlange: "10-3/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 4,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-3\"",
      craneEndApproachBack: "3'-3\""
   },
   {
      craneWeight: 5667,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "36-0",
      craneSpanFeet: 36,
      craneSpanInch: 0,
      craneWheelLoad: "9,662",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W19",
      craneFlange: "11-1/4\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "4'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 4,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "3'-3\"",
      craneEndApproachBack: "3'-3\""
   },
   {
      craneWeight: 7183,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "40-0",
      craneSpanFeet: 40,
      craneSpanInch: 0,
      craneWheelLoad: "10,042",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W19",
      craneFlange: "11-1/4\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 4,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 8421,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "45-0",
      craneSpanFeet: 45,
      craneSpanInch: 0,
      craneWheelLoad: "10,350",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W22",
      craneFlange: "12-1/2\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 4,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 9842,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "50-0",
      craneSpanFeet: 50,
      craneSpanInch: 0,
      craneWheelLoad: "10,710",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W25",
      craneFlange: "12-7/8\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "6'-3\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 4,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "4'-1\"",
      craneEndApproachBack: "4'-1\""
   },
   {
      craneWeight: 13650,
      calcCraneCapacity: 7.5,
      craneCapacity: "7-1/2",
      craneType: "Single Girder Under Running",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "60-0",
      craneSpanFeet: 60,
      craneSpanInch: 0,
      craneWheelLoad: "11,912",
      craneHpRpm: "1.5 @ 1200",
      craneBeam: "W31",
      craneFlange: "10-1/2\"",
      craneWheelDiameter: "8\"",
      craneWheelBase: "7'-6\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 4,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
   {
      craneWeight: 28846,
      calcCraneCapacity: 5,
      craneCapacity: "5",
      craneType: "Single Girder Under Running Double Girder",
      craneBaseType: "sgurdm",
      cranePrice: "$0",
      calcCraneSpan: "75-0",
      craneSpanFeet: 75,
      craneSpanInch: 0,
      craneWheelLoad: "13,212",
      craneHpRpm: "1.5 @ 1800",
      craneBeam: "W29",
      craneFlange: "0",
      craneWheelDiameter: "8\"",
      craneWheelBase: "8'-3\"",
      craneElectrificationSideApproach: 4,
      craneSideApproach: 4,
      craneDistanceAboveRail: 0,
      craneOverhang: "0\"",
      craneEndApproachFront: "0\"",
      craneEndApproachBack: "0\""
   },
] as CraneListing[];

export default cranes;
