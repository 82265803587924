import { hoists } from './hoists';
import { Hoist, HOIST_TYPES } from '../models/hoist';
import { Measurement } from '../models/crane';

const allHoists: Hoist[] = hoists.map((h) => {
    return Hoist.createFromHoistData(h);
});

const getLiftFraction = (m: Measurement): number => {
    const inch = m.inch * 0.10;
    return m.feet + inch;
}

export const getSingle = (capacity: number): Hoist[] => allHoists.filter((h: Hoist) => h.capacity === capacity);

export const getDual = (capacity: number, lift: Measurement): Hoist[] => {
    const totalLift = lift.feet + lift.inch;
    const hoistsWithinCapacityAndLift = allHoists
        .filter(h => h.capacity >= capacity)
        .filter(h => (h.lift.feet + h.lift.inch) >= totalLift);
    if (hoistsWithinCapacityAndLift) {
        return hoistsWithinCapacityAndLift;
    }
    const hoistCapacities = Array.from(new Set(allHoists.map(h => h.capacity))).sort((a, b) => a - b);
    const indexOfNextHoistCapacity = hoistCapacities.indexOf(capacity) + 1;
    if (indexOfNextHoistCapacity >= hoistCapacities.length) {
        return [];
    }
    return allHoists.filter(h => h.capacity === hoistCapacities[indexOfNextHoistCapacity]);
};

export const getHoist = (hoistType: HOIST_TYPES, manufacturer: string, capacity: number, lift: number): Hoist => {
    const availableHoists = allHoists.filter(h =>
        h.hoistType === hoistType.toString() &&
        h.manufacturer === manufacturer &&
        h.capacity >= capacity &&
        getLiftFraction(h.lift) >= lift)
        .sort((ha, hb) => {
            return ha.capacity - hb.capacity || getLiftFraction(ha.lift) - getLiftFraction(hb.lift)
        });
    if (availableHoists) {
        return availableHoists[0];
    }
    return new Hoist();
}

export const getHoists = (): Hoist[] => {
    return allHoists;
};
