import { NumberInput, StringInput } from '@uesco/crane-builder-core/models/input'
import { CRANE_SET_CAPACITY, CRANE_SET_LIFT, CRANE_SET_SPAN_FEET, CRANE_SET_SPAN_FRACTIONS, CRANE_SET_SPAN_INCH, GET_CRANE_CAPACITES } from '@/store/types'
import { Component, Vue } from 'vue-property-decorator'
import { Crane } from '../../../../crane-builder-core/models/crane';

@Component({
    computed: {
        capacity: {
            get() {
                return this.$store.state.crane.capacity;
            },
            set(c: number) {
                this.$store.commit(CRANE_SET_CAPACITY, c);
            },
        },
        spanFeet: {
            get() {
                return this.$store.state.crane.span.feet;
            },
            set(feet: number) {
                this.$store.commit(CRANE_SET_SPAN_FEET, feet);
            },
        },
        spanInch: {
            get() {
                return this.$store.state.crane.span.inch;
            },
            set(inch: number) {
                this.$store.commit(CRANE_SET_SPAN_INCH, inch);
            },
        },
        spanFractions: {
            get() {
                return this.$store.state.crane.span.fractions;
            },
            set(fractions: string) {
                this.$store.commit(CRANE_SET_SPAN_FRACTIONS, fractions);
            },
        },
        lift: {
            get() {
                return this.$store.state.crane.lift;
            },
            set(l: number) {
                this.$store.commit(CRANE_SET_LIFT, l);
            },
        },
    },
    components: {}
})
export default class CapacitySpanLift extends Vue {
    get crane(): Crane {
        return this.$store.state.crane;
    }

    get userSubmittedForm(): boolean {
        return this.$store.state.userSubmittedForm;
    }

    get capacities(): NumberInput[] {
        return this.$store.getters[GET_CRANE_CAPACITES]
            .sort((a: NumberInput, b: NumberInput) => a.value - b.value);
    }

    get fractions(): StringInput[] {
        return this.$store.state.fractions;
    }

    get isCapacityInvalid(): boolean {
        return this.userSubmittedForm && this.crane.isCapacityValid === false;
    }

    get isSpanFeetInvalid(): boolean {
        return this.userSubmittedForm && this.crane.isSpanValid === false;
    }

    get isLiftInvalid(): boolean {
        return this.userSubmittedForm && this.crane.isLiftValid === false;
    }
}
