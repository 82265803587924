import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import IRootState from './state'
import CRANE_TYPES from '@uesco/crane-builder-core/models/craneTypes'
import { Crane } from '@uesco/crane-builder-core/models/crane'
import { Quote } from '@uesco/crane-builder-core/models/quote'
import { Hoist } from '@uesco/crane-builder-core/models/hoist'
import FORM_SUBMISSION_STATUS from '@/models/formSubmissionStatus';

Vue.use(Vuex)

const state: IRootState = {
  selectedCraneType: CRANE_TYPES.NONE,
  crane: new Crane(),
  craneTypeCapacites: new Map<string, Set<number>>(),
  asceRailSizes: [
    { value: '25#', text: '25#' },
    { value: '30#', text: '30#' },
    { value: '40#', text: '40#' },
    { value: '60#', text: '60#' }
  ],
  bridgeLimits: [
    { value: 'Slow Down And Stop', text: 'Slow Down And Stop' },
    { value: 'Slow Down', text: 'Slow Down' },
    { value: 'Stop', text: 'Stop' }
  ],
  buildings: [
    { value: 'New', text: 'New' },
    { value: 'Exising', text: 'Existing' },
  ],
  collisionAvoidances: [
    { value: 'Photo Eye', text: 'Photo Eye' },
    { value: 'Two Event Laser', text: 'Two Event Laser' }
  ],
  controlMethods: [
    {
      value: 'Pushbutton Suspended From The Hoist',
      text: 'Pushbutton Suspended From The Hoist'
    },
    {
      value: 'Pushbutton Suspended From The Bridge Control Panel',
      text: 'Pushbutton Suspended From The Bridge Control Panel'
    },
    {
      value: 'Pushbutton Suspended From A Separate Track',
      text: 'Pushbutton Suspended From A Separate Track'
    },
    {
      value:
        'RC, Backup PB Suspended From Separate Track',
      text: 'Radio Control, Backup Pushbutton Suspended From A Separate Track'
    },
    {
      value: 'RC, Backup PB Suspended From Bridge',
      text: 'Radio Control, Backup Pushbutton Suspended From The Bridge'
    }
  ],
  controlTypes: [
    {
      value: 'Single speed inverter control',
      text: 'Single speed inverter control'
    },
    {
      value: 'Two step inverter control',
      text: 'Two step inverter control'
    },
    {
      value: 'Three step inverter control',
      text: 'Three step inverter control'
    }
  ],
  directions: [
    { value: '', text: 'Select North' },
    { value: 'up', text: '&uarr;' },
    { value: 'down', text: '&darr;' },
    { value: 'left', text: '&larr;' },
    { value: 'right', text: '&rarr;' }
  ],
  endpoint: 'http://localhost:3000/',
  fractions: [
    { value: '0', text: 'Fraction' },
    { value: '1/8', text: '1/8' },
    { value: '3/16', text: '3/16' },
    { value: '1/4', text: '1/4' },
    { value: '5/16', text: '5/16' },
    { value: '3/8', text: '3/8' },
    { value: '7/16', text: '7/16' },
    { value: '1/2', text: '1/2' },
    { value: '9/16', text: '9/16' },
    { value: '5/8', text: '5/8' },
    { value: '11/16', text: '11/16' },
    { value: '3/4', text: '3/4' },
    { value: '13/16', text: '13/16' },
    { value: '7/8', text: '7/8' },
    { value: '15/16', text: '15/16' }
  ],
  hoistAmounts: [
    { value: 'single', text: 'Single' },
    { value: 'dual', text: 'Dual' }
  ],
  hoistAmount: '',
  hoistManufacturers: [
    { value: 'rm', text: 'R&M Material Handling' },
    { value: 'detroit', text: 'Detroit' }
  ],
  hoistACapacity: 0,
  hoistBCapacity: 0,
  hoistManufacturer: new Hoist(),
  inches: [
    { value: 0, text: 'Inch' },
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
    { value: 4, text: '4' },
    { value: 5, text: '5' },
    { value: 6, text: '6' },
    { value: 7, text: '7' },
    { value: 8, text: '8' },
    { value: 9, text: '9' },
    { value: 10, text: '10' },
    { value: 11, text: '11' },
    { value: 12, text: '12' }
  ],
  projectTimelines: [
    { value: 'Budget', text: 'Budget' },
    { value: 'Immediate', text: 'Immediate' },
    { value: 'Next 6 Months', text: 'Next 6 Months' },
    { value: 'Next 12 Months', text: 'Next 12 Months' },
  ],
  quote: new Quote(),
  quoteRequested: false,
  serviceRequirements: [
    { value: 'Indoor', text: 'Indoor' },
    { value: 'Outdoor', text: 'Outdoor' },
    { value: 'Corrosive', text: 'Corrosive' },
    { value: 'Explosion Proof', text: 'Explosion Proof' }
  ],
  trolleyLimits: [
    { value: 'Slow Down And Stop', text: 'Slow Down And Stop' },
    { value: 'Slow Down', text: 'Slow Down' },
    { value: 'Stop', text: 'Stop' }
  ],
  usStates: [
    { value: '', text: 'Select State' },
    { value: 'Alabama', text: 'AL : Alabama' },
    { value: 'Alaska', text: 'AK : Alaska' },
    { value: 'Arizona', text: 'AZ : Arizona' },
    { value: 'Arkansas', text: 'AR : Arkansas' },
    { value: 'California', text: 'CA : California' },
    { value: 'Colorado', text: 'CO : Colorado' },
    { value: 'Connecticut', text: 'CT : Connecticut' },
    { value: 'Delaware', text: 'DE : Delaware' },
    { value: 'District of Columbia', text: 'DC : District of Columbia' },
    { value: 'Florida', text: 'FL : Florida' },
    { value: 'Georgia', text: 'GA : Georgia' },
    { value: 'Hawaii', text: 'HI : Hawaii' },
    { value: 'Idaho', text: 'ID : Idaho' },
    { value: 'Illinois', text: 'IL : Illinois' },
    { value: 'Indiana', text: 'IN : Indiana' },
    { value: 'Iowa', text: 'IA : Iowa' },
    { value: 'Kansas', text: 'KS : Kansas' },
    { value: 'Kentucky', text: 'KY : Kentucky' },
    { value: 'Louisiana', text: 'LA : Louisiana' },
    { value: 'Maine', text: 'ME : Maine' },
    { value: 'Maryland', text: 'MD : Maryland' },
    { value: 'Massachusetts', text: 'MA : Massachusetts' },
    { value: 'Michigan', text: 'MI : Michigan' },
    { value: 'Minnesota', text: 'MN : Minnesota' },
    { value: 'Mississippi', text: 'MS : Mississippi' },
    { value: 'Missouri', text: 'MO : Missouri' },
    { value: 'Montana', text: 'MT : Montana' },
    { value: 'Nebraska', text: 'NE : Nebraska' },
    { value: 'Nevada', text: 'NV : Nevada' },
    { value: 'New Hampshire', text: 'NH : New Hampshire' },
    { value: 'New Jersey', text: 'NJ : New Jersey' },
    { value: 'New Mexico', text: 'NM : New Mexico' },
    { value: 'New York', text: 'NY : New York' },
    { value: 'North Carolina', text: 'NC : North Carolina' },
    { value: 'North Dakota', text: 'ND : North Dakota' },
    { value: 'Ohio', text: 'OH : Ohio' },
    { value: 'Oklahoma', text: 'OK : Oklahoma' },
    { value: 'Oregon', text: 'OR : Oregon' },
    { value: 'Pennsylvania', text: 'PA : Pennsylvania' },
    { value: 'Rhode Island', text: 'RI : Rhode Island' },
    { value: 'South Carolina', text: 'SC : South Carolina' },
    { value: 'South Dakota', text: 'SD : South Dakota' },
    { value: 'Tennessee', text: 'TN : Tennessee' },
    { value: 'Texas', text: 'TX : Texas' },
    { value: 'Utah', text: 'UT : Utah' },
    { value: 'Vermont', text: 'VT : Vermont' },
    { value: 'Virginia', text: 'VA : Virginia' },
    { value: 'Washington', text: 'WA : Washington' },
    { value: 'West Virginia', text: 'WV : West Virginia' },
    { value: 'Wisconsin', text: 'WI : Wisconsin' },
    { value: 'Wyoming', text: 'WY : Wyoming' },
  ],
  userSubmittedForm: false,
  voltages: [
    { value: '460/3/60', text: '460/3/60' },
    { value: '230/3/60', text: '230/3/60' },
    { value: '208/3/60', text: '208/3/60' },
    { value: '575/3/60', text: '575/3/60' }
  ],
  warningDevices: [
    { value: 'Horn', text: 'Horn' },
    { value: 'Strobe Light', text: 'Strobe Light' }
  ],
  formSubmissionStatus: FORM_SUBMISSION_STATUS.NOT_SENT,
  showLoading: false,
  notifyPdfCreated: false,
  pdfLink: '',
};

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters
});
