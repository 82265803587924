import { ActionTree } from 'vuex'
import BuildRequest from '@uesco/crane-builder-core/models/buildRequest';
import IRootState from './state'
import { SET_FORM_SUBMISSION_STATUS, SET_NOTIFY_PDF_CREATED, SET_PDF_LINK, SET_SHOW_LOADING, SUBMIT_BUILD_REQUEST } from './types'
import FORM_SUBMISSION_STATUS from '@/models/formSubmissionStatus';

const actions: ActionTree<IRootState, IRootState> = {
    async [SUBMIT_BUILD_REQUEST]({ state, commit }, buildRequest: BuildRequest) {
        commit(SET_FORM_SUBMISSION_STATUS, FORM_SUBMISSION_STATUS.SUBMITTED);
        commit(SET_SHOW_LOADING, true);
        try {
            const response = await fetch(state.endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(buildRequest),
            });
            const resp = await response.json();
            if (resp.status === 'ok') {
                window.open(resp.fileLoc, '_blank');
                commit(SET_PDF_LINK, resp.fileLoc);
                commit(SET_NOTIFY_PDF_CREATED, true);
            } else {
                if (resp.fileLoc && resp.fileLoc.length) {
                    window.open(resp.fileLoc, '_blank');
                    commit(SET_PDF_LINK, resp.fileLoc);
                    commit(SET_NOTIFY_PDF_CREATED, true);
                }
                alert(resp.message);
            }
        } catch (err) {
            console.error(err);
            alert(`There was an issue sending your request: Error: ${err}`)
        } finally {
            commit(SET_SHOW_LOADING, false);
        }
    }
}

export default actions;
