import { Component, Vue } from 'vue-property-decorator'
import {
    CRANE_SET_ASCE_RAIL_SIZE,
    CRANE_SET_CONTROL_METHOD,
    CRANE_SET_CONTROL_TYPE,
    CRANE_SET_SERVICE_REQUIREMENT,
    CRANE_SET_SPEED,
    CRANE_SET_VOLTAGE,
    GET_SPEED,
} from '@/store/types';
import { Crane } from '@uesco/crane-builder-core/models/crane';
import { StringInput } from '@uesco/crane-builder-core/models/input';
import CRANE_TYPES from '../../../../crane-builder-core/models/craneTypes';

@Component({
    components: {}
})
export default class Requirements extends Vue {
    get crane(): Crane {
        return this.$store.state.crane;
    }

    get userSubmittedForm(): boolean {
        return this.$store.state.userSubmittedForm;
    }

    get displayAsceRailSize(): boolean {
        if (this.$store.state.selectedCraneType === CRANE_TYPES.SINGLE_GIRDER_UNDER_RUNNING) {
            this.asceRailSize = '';
            return false;
        }
        return true;
    }

    get asceRailSizes(): StringInput[] {
        return this.$store.state.asceRailSizes;
    }

    get asceRailSize(): string {
        return this.crane.asceRail.size;
    }

    set asceRailSize(size: string) {
        this.$store.commit(CRANE_SET_ASCE_RAIL_SIZE, size);
    }

    get controlMethods(): StringInput[] {
        return this.$store.state.controlMethods;
    }

    get controlMethod(): string {
        return this.crane.controlMethod;
    }

    set controlMethod(cm: string) {
        this.$store.commit(CRANE_SET_CONTROL_METHOD, cm);
    }

    get controlTypes(): StringInput[] {
        return this.$store.state.controlTypes;
    }

    get controlType(): string {
        return this.crane.controlType;
    }

    set controlType(ct: string) {
        this.$store.commit(CRANE_SET_CONTROL_TYPE, ct);
    }

    get serviceRequirements(): StringInput[] {
        return this.$store.state.serviceRequirements;
    }

    get serviceRequirement(): string {
        return this.crane.serviceRequirement;
    }

    set serviceRequirement(sr: string) {
        this.$store.commit(CRANE_SET_SERVICE_REQUIREMENT, sr);
    }

    get speeds(): StringInput[] {
        return this.$store.getters[GET_SPEED];
    }

    get speed(): string {
        return this.crane.speed;
    }

    set speed(s: string) {
        this.$store.commit(CRANE_SET_SPEED, s);
    }

    get voltages(): StringInput[] {
        return this.$store.state.voltages;
    }

    get voltage(): string {
        return this.crane.voltage;
    }

    set voltage(v: string) {
        this.$store.commit(CRANE_SET_VOLTAGE, v);
    }

    get isControlMethodInvalid(): boolean {
        return this.userSubmittedForm && this.crane.isControlMethodValid === false;
    }

    get isControlTypeInvalid(): boolean {
        return this.userSubmittedForm && this.crane.isControlTypeValid === false;
    }

    get isServiceRequirementInvalid(): boolean {
        return this.userSubmittedForm && this.crane.isServiceRequirementValid === false;
    }

    get isSpeedInvalid(): boolean {
        return this.userSubmittedForm && this.crane.isSpeedValid === false;
    }

    get isVoltageInvalid(): boolean {
        return this.userSubmittedForm && this.crane.isVoltageValid === false;
    }
}
