import { Crane } from "./crane";
import CRANE_TYPES from "./craneTypes";
import { Quote } from "./quote";

export default class BuildRequest {
    public craneType: CRANE_TYPES;

    public crane: Crane;

    public hoistAmount: string;

    public hoistACapacity: number;

    public hoistBCapacity: number;

    public hoistManufacturer: string;

    public quote: Quote;

    public quoteReqested: boolean;

    constructor(craneType: CRANE_TYPES, crane: Crane, hoistAmount: string, hoistACapacity: number,
        hoistBCapacity: number, hoistManufacturer: string, quote: Quote, quoteRequested: boolean) {
        this.craneType = craneType;
        this.crane = crane;
        this.hoistAmount = hoistAmount;
        this.hoistACapacity = hoistACapacity;
        this.hoistBCapacity = hoistBCapacity;
        this.hoistManufacturer = hoistManufacturer;
        this.quote = quote;
        this.quoteReqested = quoteRequested;
    }
}
