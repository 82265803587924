import { Component, Vue } from 'vue-property-decorator'
import {
    CRANE_SET_BRIDGE_LIMIT,
    CRANE_SET_COLLISION_AVOIDANCE,
    CRANE_SET_TROLLEY_LIMIT,
    CRANE_SET_WARNING_DEVICE,
} from '@/store/types';
import { Crane } from '@uesco/crane-builder-core/models/crane';
import { StringInput } from '@uesco/crane-builder-core/models/input';

@Component({
    components: {}
})
export default class Options extends Vue {
    get crane(): Crane {
        return this.$store.state.crane;
    }

    get bridgeLimits(): StringInput[] {
        return this.$store.state.bridgeLimits;
    }

    get bridgeLimit(): string {
        return this.crane.bridgeLimit;
    }

    set bridgeLimit(bl: string) {
        this.$store.commit(CRANE_SET_BRIDGE_LIMIT, bl);
    }

    get collisionAvoidances(): StringInput[] {
        return this.$store.state.collisionAvoidances;
    }

    get collisionAvoidance(): string {
        return this.crane.collisionAvoidance;
    }

    set collisionAvoidance(ca: string) {
        this.$store.commit(CRANE_SET_COLLISION_AVOIDANCE, ca);
    }

    get trolleyLimits(): StringInput[] {
        return this.$store.state.trolleyLimits;
    }

    get trolleyLimit(): string {
        return this.crane.trolleyLimit;
    }

    set trolleyLimit(tl: string) {
        this.$store.commit(CRANE_SET_TROLLEY_LIMIT, tl);
    }

    get warningDevices(): StringInput[] {
        return this.$store.state.warningDevices;
    }

    get warningDevice(): string {
        return this.crane.warningDevice;
    }

    set warningDevice(wd: string) {
        this.$store.commit(CRANE_SET_WARNING_DEVICE, wd);
    }
}
