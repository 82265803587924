import Vue from 'vue'
import { getCraneTypeCapacites } from '@uesco/crane-builder-core/data/craneRepository';
import App from './App.vue'
import 'jquery'
import 'popper.js'
import store from './store'
import './styles.scss';
import { SET_CRANE_TYPE_CAPACITES, SET_ENDPOINT } from './store/types';

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
  created() {
    const cct = getCraneTypeCapacites();
    this.$store.commit(SET_CRANE_TYPE_CAPACITES, cct);
    this.$store.commit(SET_ENDPOINT, process.env.NODE_ENV === 'development');
  },
}).$mount('#app')
