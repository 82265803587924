import { Component, Vue, Watch } from 'vue-property-decorator'
import { GET_CRANE_CAPACITES, HOIST_SET_HOIST_A_CAPACITY, HOIST_SET_HOIST_B_CAPACITY, HOIST_SET_HOIST_MANUFACTURER, SET_HOIST_AMOUNT } from '@/store/types';
import { NumberInput, StringInput } from '@uesco/crane-builder-core/models/input';
import { Hoist } from '@uesco/crane-builder-core/models/hoist';
import { Crane } from '@uesco/crane-builder-core/models/crane';
import { getHoists } from '@uesco/crane-builder-core/data/hoistRepository';

@Component({
    components: {}
})
export default class HoistComponent extends Vue {
    get crane(): Crane {
        return this.$store.state.crane;
    }

    get craneCapacity(): number {
        return this.$store.state.crane.capacity || 0;
    }

    get userSubmittedForm(): boolean {
        return this.$store.state.userSubmittedForm;
    }

    get hoistAmounts(): StringInput[] {
        return this.$store.state.hoistAmounts;
    }

    get hoistAmount(): string {
        return this.$store.state.hoistAmount;
    }

    set hoistAmount(amt: string) {
        this.$store.commit(SET_HOIST_AMOUNT, amt);
        if (amt === 'single') {
            this.hoistA = this.craneCapacity;
        }
    }

    get isHoistAmountInvalid(): boolean {
        return this.userSubmittedForm && this.hoistAmount === '';
    }

    get showInfoMessage(): boolean {
        return this.crane.capacity <= 0 || this.crane.lift <= 0;
    }

    get hoistManufacturer(): Hoist {
        return this.$store.state.hoistManufacturer;
    }

    set hoistManufacturer(h: Hoist) {
        this.$store.commit(HOIST_SET_HOIST_MANUFACTURER, h);
    }

    get hoistImage(): string {
        if (this.hoistManufacturer && this.hoistManufacturer.manufacturer && this.crane.craneBaseType) {
            return `https://uesco-cdn.nyc3.cdn.digitaloceanspaces.com/builder/${this.hoistManufacturer.manufacturer}-${this.crane.craneBaseType}.png`;
        }
        return '';
    }

    get hoistA(): number {
        return this.$store.state.hoistACapacity;
    }

    set hoistA(h: number) {
        this.$store.commit(HOIST_SET_HOIST_A_CAPACITY, h);
    }

    get isHoistAInvalid(): boolean {
        return this.userSubmittedForm && this.hoistA <= 0;
    }

    get hoistB(): number {
        return this.$store.state.hoistBCapacity;
    }

    set hoistB(h: number) {
        this.$store.commit(HOIST_SET_HOIST_B_CAPACITY, h);
    }

    get isHoistBInvalid(): boolean {
        return this.userSubmittedForm && this.hoistAmount === 'dual' && this.hoistB <= 0;
    }

    get hoists(): Hoist[] {
        const allHoists = getHoists();
        const hoistMap = new Map<string, Hoist>();
        const filteredHoists: Hoist[] = [];

        allHoists.forEach((h: Hoist) => {
            if (!hoistMap.has(h.manufacturer)) {
                hoistMap.set(h.manufacturer, h);
                filteredHoists.push(h);
            }
        });
        return filteredHoists;
    }

    get capacities(): NumberInput[] {
        const craneCapacites: NumberInput[] = this.$store.getters[GET_CRANE_CAPACITES]
            .sort((a: NumberInput, b: NumberInput) => a.value - b.value);
        const highestCap = (craneCapacites.length) ? this.$store.state.crane.capacity : 0;
        const caps: NumberInput[] = craneCapacites.filter(v => v.value <= highestCap);
        return caps;
    }

    get isHoistManufacturerInvalid(): boolean {
        return this.userSubmittedForm && !this.hoistManufacturer;
    }

    @Watch('craneCapacity')
    onCraneCapacityChanged(newCapacity: number): void {
        if (this.hoistAmount === 'single') {
            this.hoistA = newCapacity;
        } else {
            this.hoistA = (this.hoistA <= newCapacity) ? this.hoistA : 0;
            this.hoistB = (this.hoistB <= newCapacity) ? this.hoistB : 0;
        }
    }
}
