import { Crane, CraneListing } from '../models/crane';
import cranes from './cranes';

const craneTypeCapacities = new Map<string, Set<number>>();

cranes.forEach((c: CraneListing) => {
    const craneBaseType = c.craneBaseType;
    const capacity = c.calcCraneCapacity;
    const capacites: Set<number> = craneTypeCapacities.get(craneBaseType) || new Set<number>();
    capacites.add(capacity);
    craneTypeCapacities.set(craneBaseType, capacites);
});

export const getCraneTypeCapacites = (): Map<string, Set<number>> => craneTypeCapacities;

export const getCraneListing = (crane: Crane): CraneListing => {
    const availableCranes = cranes.filter(c => c.craneType === crane.craneType &&
        c.calcCraneCapacity >= crane.capacity &&
        c.craneSpanFeet >= crane.span.feet)
        .sort((ca, cb) => {
            return ca.calcCraneCapacity - cb.calcCraneCapacity || ca.craneSpanFeet - cb.craneSpanFeet;
        });
    if (availableCranes.length) {
        return availableCranes[0];
    }
    return {
        craneWeight: 0,
        calcCraneCapacity: 0,
        craneCapacity: "",
        craneType: "",
        craneBaseType: '',
        cranePrice: "$0.00",
        calcCraneSpan: "0",
        craneSpanFeet: 0,
        craneSpanInch: 0,
        craneWheelLoad: "0",
        craneHpRpm: "0",
        craneBeam: "0",
        craneFlange: "0",
        craneWheelDiameter: "0\"",
        craneWheelBase: "0\"",
        craneElectrificationSideApproach: 0,
        craneSideApproach: 0,
        craneDistanceAboveRail: 0,
        craneOverhang: "0\"",
        craneEndApproachFront: "0\"",
        craneEndApproachBack: "0\""
    } as CraneListing;
};
