import CRANE_TYPES from '@uesco/crane-builder-core/models/craneTypes';
import { Crane } from '@uesco/crane-builder-core/models/crane'
import { Quote } from '@uesco/crane-builder-core/models/quote';
import { Hoist } from '@uesco/crane-builder-core/models/hoist';
import { checkInputIsNumberOrParseString } from '@/utils';
import { MutationTree } from 'vuex'
import IRootState from './state'
import { CRANE_SET_ASCE_RAIL_FEET, CRANE_SET_ASCE_RAIL_FRACTIONS, CRANE_SET_ASCE_RAIL_INCH, CRANE_SET_ASCE_RAIL_SIZE, CRANE_SET_BRIDGE_LIMIT, CRANE_SET_CAPACITY, CRANE_SET_COLLISION_AVOIDANCE, CRANE_SET_CONTROL_METHOD, CRANE_SET_CONTROL_TYPE, CRANE_SET_DIRECTION, CRANE_SET_LIFT, CRANE_SET_LOWEST_OBSTRUCTION_HEIGHT_FEET, CRANE_SET_LOWEST_OBSTRUCTION_HEIGHT_FRACTIONS, CRANE_SET_LOWEST_OBSTRUCTION_HEIGHT_INCH, CRANE_SET_SERVICE_REQUIREMENT, CRANE_SET_SPAN_FEET, CRANE_SET_SPAN_FRACTIONS, CRANE_SET_SPAN_INCH, CRANE_SET_SPEED, CRANE_SET_TROLLEY_LIMIT, CRANE_SET_VOLTAGE, CRANE_SET_WARNING_DEVICE, HOIST_SET_HOIST_A_CAPACITY, HOIST_SET_HOIST_B_CAPACITY, HOIST_SET_HOIST_MANUFACTURER, QUOTE_SET_BUILDING, QUOTE_SET_CITY, QUOTE_SET_COMPANY, QUOTE_SET_EMAIL, QUOTE_SET_INSTALLATION_REQUIRED, QUOTE_SET_NAME, QUOTE_SET_PHONE, QUOTE_SET_PROJECT_TIMELINE, QUOTE_SET_RUNWAY_REQUIRED, QUOTE_SET_RUNWAY_SPEC, QUOTE_SET_STATE, RESET, SET_CRANE_TYPE_CAPACITES, SET_ENDPOINT, SET_FORM_SUBMISSION_STATUS, SET_HOIST_AMOUNT, SET_NOTIFY_PDF_CREATED, SET_PDF_LINK, SET_QUOTE_REQUTESTED, SET_SELECTED_CRANE_TYPE, SET_SHOW_LOADING, SET_USER_SUBMITTED_FORM } from './types'
import FORM_SUBMISSION_STATUS from '@/models/formSubmissionStatus';

const mutations: MutationTree<IRootState> = {
    [CRANE_SET_ASCE_RAIL_FEET](state, feet: number) {
        state.crane.asceRail.measurements.feet = checkInputIsNumberOrParseString(feet);
    },
    [CRANE_SET_ASCE_RAIL_INCH](state, inch: number) {
        state.crane.asceRail.measurements.inch = checkInputIsNumberOrParseString(inch);
    },
    [CRANE_SET_ASCE_RAIL_FRACTIONS](state, fractions: string) {
        state.crane.asceRail.measurements.fractions = fractions;
    },
    [CRANE_SET_ASCE_RAIL_SIZE](state, size: string) {
        state.crane.asceRail.size = size;
    },
    [CRANE_SET_BRIDGE_LIMIT](state, bridgeLimit: string) {
        state.crane.bridgeLimit = bridgeLimit;
    },
    [CRANE_SET_CAPACITY](state, capacity: number) {
        state.crane.capacity = checkInputIsNumberOrParseString(capacity);
    },
    [CRANE_SET_COLLISION_AVOIDANCE](state, collisionAvoidance: string) {
        state.crane.collisionAvoidance = collisionAvoidance;
    },
    [CRANE_SET_CONTROL_METHOD](state, controlMethod: string) {
        state.crane.controlMethod = controlMethod;
    },
    [CRANE_SET_CONTROL_TYPE](state, controlType: string) {
        state.crane.controlType = controlType;
    },
    [CRANE_SET_DIRECTION](state, direction: string) {
        state.crane.direction = direction;
    },
    [CRANE_SET_LIFT](state, lift: number) {
        state.crane.lift = checkInputIsNumberOrParseString(lift);
    },
    [CRANE_SET_LOWEST_OBSTRUCTION_HEIGHT_FEET](state, feet: number) {
        state.crane.lowestObstructionHeight.feet = checkInputIsNumberOrParseString(feet);
    },
    [CRANE_SET_LOWEST_OBSTRUCTION_HEIGHT_INCH](state, inch: number) {
        state.crane.lowestObstructionHeight.inch = checkInputIsNumberOrParseString(inch);
    },
    [CRANE_SET_LOWEST_OBSTRUCTION_HEIGHT_FRACTIONS](state, fractions: string) {
        state.crane.lowestObstructionHeight.fractions = fractions;
    },
    [CRANE_SET_SERVICE_REQUIREMENT](state, serviceRequirement: string) {
        state.crane.serviceRequirement = serviceRequirement;
    },
    [CRANE_SET_SPAN_FEET](state, feet: number) {
        state.crane.span.feet = checkInputIsNumberOrParseString(feet);
    },
    [CRANE_SET_SPAN_INCH](state, inch: number) {
        state.crane.span.inch = checkInputIsNumberOrParseString(inch);
    },
    [CRANE_SET_SPAN_FRACTIONS](state, fraction: string) {
        state.crane.span.fractions = fraction;
    },
    [CRANE_SET_SPEED](state, speed: string) {
        state.crane.speed = speed;
    },
    [CRANE_SET_TROLLEY_LIMIT](state, trolleyLimit: string) {
        state.crane.trolleyLimit = trolleyLimit;
    },
    [CRANE_SET_VOLTAGE](state, voltage: string) {
        state.crane.voltage = voltage;
    },
    [CRANE_SET_WARNING_DEVICE](state, warningDevice: string) {
        state.crane.warningDevice = warningDevice;
    },
    [HOIST_SET_HOIST_MANUFACTURER](state, hoist: Hoist) {
        state.hoistManufacturer = hoist;
    },
    [HOIST_SET_HOIST_A_CAPACITY](state, capacity: number) {
        state.hoistACapacity = checkInputIsNumberOrParseString(capacity);
    },
    [HOIST_SET_HOIST_B_CAPACITY](state, capacity: number) {
        state.hoistBCapacity = checkInputIsNumberOrParseString(capacity);
    },
    [QUOTE_SET_NAME](state, name: string) {
        state.quote.name = name;
    },
    [QUOTE_SET_COMPANY](state, company: string) {
        state.quote.company = company;
    },
    [QUOTE_SET_CITY](state, city: string) {
        state.quote.city = city;
    },
    [QUOTE_SET_STATE](state, st: string) {
        state.quote.state = st;
    },
    [QUOTE_SET_PHONE](state, phone: string) {
        state.quote.phone = phone;
    },
    [QUOTE_SET_EMAIL](state, email: string) {
        state.quote.email = email;
    },
    [QUOTE_SET_BUILDING](state, building: string) {
        state.quote.building = building;
    },
    [QUOTE_SET_RUNWAY_REQUIRED](state, required: boolean) {
        state.quote.runwayRequired = required;
    },
    [QUOTE_SET_RUNWAY_SPEC](state, spec: string) {
        state.quote.runwaySpecifications = spec;
    },
    [QUOTE_SET_INSTALLATION_REQUIRED](state, req: boolean) {
        state.quote.installationRequired = req;
    },
    [QUOTE_SET_PROJECT_TIMELINE](state, timeline: string) {
        state.quote.projectTimeline = timeline;
    },
    [SET_SELECTED_CRANE_TYPE](state, craneType: string) {
        state.crane = new Crane();
        switch (craneType) {
            case CRANE_TYPES.DOUBLE_GIRDER_TOP_RUNNING.toString():
                state.crane.craneType = CRANE_TYPES.DOUBLE_GIRDER_TOP_RUNNING;
                state.crane.craneBaseType = 'dgtrdm';
                break;
            case CRANE_TYPES.SINGLE_GIRDER_TOP_RUNNING.toString():
                state.crane.craneType = CRANE_TYPES.SINGLE_GIRDER_TOP_RUNNING;
                state.crane.craneBaseType = 'sgtrdm';
                break;
            case CRANE_TYPES.SINGLE_GIRDER_UNDER_RUNNING.toString():
                state.crane.craneType = CRANE_TYPES.SINGLE_GIRDER_UNDER_RUNNING;
                state.crane.craneBaseType = 'sgurdm';
                break;
            default:
                state.crane.craneType = CRANE_TYPES.NONE;
                state.crane.craneBaseType = '';
        }
        state.selectedCraneType = craneType;
    },
    [SET_CRANE_TYPE_CAPACITES](state, craneTypeCapacites: Map<string, Set<number>>) {
        state.craneTypeCapacites = craneTypeCapacites;
    },
    [SET_HOIST_AMOUNT](state, amount: string) {
        state.hoistAmount = amount;
    },
    [SET_QUOTE_REQUTESTED](state, requested: boolean) {
        state.quoteRequested = requested;
    },
    [SET_USER_SUBMITTED_FORM](state, submitted: boolean) {
        state.userSubmittedForm = submitted;
    },
    [SET_FORM_SUBMISSION_STATUS](state, status: FORM_SUBMISSION_STATUS) {
        state.formSubmissionStatus = status;
    },
    [SET_ENDPOINT](state, isDev: boolean) {
        if (isDev) {
            state.endpoint = 'http://localhost:3000/build/';
        } else {
            state.endpoint = '/build/';
        }
    },
    [SET_SHOW_LOADING](state, show: boolean) {
        state.showLoading = show;
    },
    [SET_NOTIFY_PDF_CREATED](state, notify: boolean) {
        state.notifyPdfCreated = notify;
    },
    [SET_PDF_LINK](state, link: string) {
        state.pdfLink = link;
    },
    [RESET](state) {
        state.selectedCraneType = CRANE_TYPES.NONE;
        state.crane = new Crane();
        state.hoistACapacity = 0;
        state.hoistBCapacity = 0;
        state.hoistAmount = '';
        state.hoistManufacturer = new Hoist();
        state.quote = new Quote();
        state.quoteRequested = false;
        state.userSubmittedForm = false;
        state.formSubmissionStatus = FORM_SUBMISSION_STATUS.NOT_SENT;
        state.pdfLink = '';
        state.notifyPdfCreated = false;
    }
};

export default mutations
