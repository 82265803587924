
import { Component, Vue } from 'vue-property-decorator'
import BuildRequest from '@uesco/crane-builder-core/models/buildRequest';
import { Crane } from '@uesco/crane-builder-core/models/crane';
import { Quote } from '@uesco/crane-builder-core/models/quote';
import CRANE_TYPES from '@uesco/crane-builder-core/models/craneTypes';
import CapacitySpanLift from "@/components/capacity-span-lift";
import Spinner from '@/components/spinner';
import Direction from '@/components/direction';
import CraneDetails from '@/components/details';
import Requirements from '@/components/requirements';
import Options from '@/components/options';
import QuoteForm from '@/components/quote';
import HoistComponent from '@/components/hoist';
import {
    GET_CRANE_IMAGES,
    SET_USER_SUBMITTED_FORM,
    SUBMIT_BUILD_REQUEST,
    GET_NOTIFY_PDF_CREATED,
    SET_SHOW_LOADING,
    GET_SHOW_LOADING,
    GET_PDF_LINK,
} from '@/store/types';
import { CraneImages } from '@/models/craneImages';

@Component({
    components: {
        CapacitySpanLift,
        Direction,
        CraneDetails,
        Requirements,
        Options,
        QuoteForm,
        HoistComponent,
        Spinner,
    }
})
export default class CraneBuilder extends Vue {
    private hasErrors = false;

    get craneType(): CRANE_TYPES {
        return this.$store.state.selectedCraneType;
    }

    get crane(): Crane {
        return this.$store.state.crane;
    }

    get craneImages(): CraneImages {
        return this.$store.getters[GET_CRANE_IMAGES];
    }

    get quote(): Quote {
        return this.$store.state.quote;
    }

    get hoistAmount(): string {
        return this.$store.state.hoistAmount;
    }

    get hoistACapacity(): number {
        return this.$store.state.hoistACapacity;
    }

    get hoistBCapacity(): number {
        return this.$store.state.hoistBCapacity;
    }

    get hoistManufacturer(): string {
        return this.$store.state.hoistManufacturer.manufacturer;
    }

    get quoteRequested(): boolean {
        return this.$store.state.quoteRequested;
    }

    get notifyPdfCreated(): boolean {
        return this.$store.getters[GET_NOTIFY_PDF_CREATED];
    }

    get pdfLink(): string {
        return this.$store.getters[GET_PDF_LINK];
    }

    get showLoading(): boolean {
        return this.$store.getters[GET_SHOW_LOADING];
      }

    private handlePdfClick() {
        this.$store.commit(SET_USER_SUBMITTED_FORM, true);
        this.hasErrors = !this.crane.isValid;

        if (this.hasErrors) {
            const invalid = document.getElementsByClassName('is-invalid');
            if (invalid.length) {
                (invalid[0] as HTMLElement).focus();
            }
            return;
        }

        const buildRequest = new BuildRequest(
            this.craneType,
            this.crane,
            this.hoistAmount,
            this.hoistACapacity,
            this.hoistBCapacity,
            this.hoistManufacturer,
            this.quote,
            this.quoteRequested);

        this.$store.commit(SET_SHOW_LOADING, true);
        this.$store.dispatch(SUBMIT_BUILD_REQUEST, buildRequest);
    }
}
