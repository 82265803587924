











































import { Component, Vue } from "vue-property-decorator";
import CRANE_TYPES from "@uesco/crane-builder-core/models/craneTypes";
import CraneBuilder from "./components/crane-builder";
import {
  GET_CRANE_TYPES,
  SET_SELECTED_CRANE_TYPE,
  RESET,
} from "./store/types";

@Component({
  components: {
    CraneBuilder,
  },
})
export default class App extends Vue {
  get craneTypes(): string[] {
    return this.$store.getters[GET_CRANE_TYPES];
  }

  get craneType(): string {
    return this.$store.state.selectedCraneType;
  }

  get isValidCraneType(): boolean {
    return this.craneType !== CRANE_TYPES.NONE;
  }

  get isNoneCraneType(): boolean {
    return this.craneType === CRANE_TYPES.NONE;
  }

  setCraneType(ct: string): void {
    this.$store.commit(SET_SELECTED_CRANE_TYPE, ct);
  }

  resetCraneType(): void {
    this.$store.commit(RESET);
  }
}
