import { Component, Prop, Vue } from 'vue-property-decorator'
import {
    CRANE_SET_ASCE_RAIL_FEET,
    CRANE_SET_ASCE_RAIL_FRACTIONS,
    CRANE_SET_ASCE_RAIL_INCH,
    CRANE_SET_LOWEST_OBSTRUCTION_HEIGHT_FEET,
    CRANE_SET_LOWEST_OBSTRUCTION_HEIGHT_FRACTIONS,
    CRANE_SET_LOWEST_OBSTRUCTION_HEIGHT_INCH,
} from '@/store/types';
import { Crane } from '@uesco/crane-builder-core/models/crane';
import { StringInput } from '@uesco/crane-builder-core/models/input';

@Component({
    components: {}
})
export default class Details extends Vue {
    @Prop({ required: true }) craneTopImage!: string;

    @Prop({ required: true }) craneBottomImage!: string;

    get crane(): Crane {
        return this.$store.state.crane;
    }

    get fractions(): StringInput[] {
        return this.$store.state.fractions;
    }

    get asceRailFeet(): number {
        return this.crane.asceRail.measurements.feet;
    }

    set asceRailFeet(feet: number) {
        this.$store.commit(CRANE_SET_ASCE_RAIL_FEET, feet);
    }

    get asceRailInch(): number {
        return this.crane.asceRail.measurements.inch;
    }

    set asceRailInch(inch: number) {
        this.$store.commit(CRANE_SET_ASCE_RAIL_INCH, inch);
    }

    get asceRailFractions(): string {
        return this.crane.asceRail.measurements.fractions;
    }

    set asceRailFractions(fractions: string) {
        this.$store.commit(CRANE_SET_ASCE_RAIL_FRACTIONS, fractions);
    }

    get lowestObstructionHeightFeet(): number {
        return this.crane.lowestObstructionHeight.feet;
    }

    set lowestObstructionHeightFeet(feet: number) {
        this.$store.commit(CRANE_SET_LOWEST_OBSTRUCTION_HEIGHT_FEET, feet);
    }

    get lowestObstructionHeightInch(): number {
        return this.crane.lowestObstructionHeight.inch;
    }

    set lowestObstructionHeightInch(inch: number) {
        this.$store.commit(CRANE_SET_LOWEST_OBSTRUCTION_HEIGHT_INCH, inch);
    }

    get lowestObstructionHeightFractions(): string {
        return this.crane.lowestObstructionHeight.fractions;
    }

    set lowestObstructionHeightFractions(fractions: string) {
        this.$store.commit(CRANE_SET_LOWEST_OBSTRUCTION_HEIGHT_FRACTIONS, fractions);
    }
}
